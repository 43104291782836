import styles from "./Candidates.module.css";
import {makeHttpPostRequest, makePostRequest} from "../../api/submit";
import React, {useContext, useEffect, useState} from "react";
import {API, BACKEND} from "../../constants";
import {CommentSection} from "react-comments-section";
import './CandidatesComments.css'
import Popup from "../../components/Popup/Base";
import {generateColor} from "../../utils/avatarColor";
import Button from "../../components/Buttons/Button";
import FileSaver from 'file-saver';
import Loader from "../../components/General/Loader";

const convertComments = (comments) => {
    comments = comments.map(comment => ({
        userId: comment.author,
        comId: comment.id,
        fullName: comment.author_name,
        text: comment.text,
        avatarUrl: `https://ui-avatars.com/api/name=${comment.author_name}&background=${generateColor(comment.author_name).replace('#', '')}`,
        replies: []
    }))
    return comments;
}


const CandidateInfoPopup = ({user, candidateInfo, setCandidateInfo}) => {

    const [comments, setComments] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (candidateInfo !== undefined) {
            makePostRequest(`${BACKEND}${API}/d/candidates/fetch_comments/`, {
                interview_id: candidateInfo?.interviewId,
            })
                .then((response) => {
                    setComments(convertComments(response.comments))
                })
        }
    }, [candidateInfo])

    return (<>
            <Loader isLoading={isLoading} text={"Downloading the Report..."}/>
            <Popup
                isOpen={candidateInfo !== undefined}
                content={
                    <div className={styles.comment_section}>
                        {(candidateInfo?.statusString || "").toLowerCase() === 'completed' && <Button
                            title={"Download PDF Report"}
                            onClick={() => {
                                setIsLoading(true)
                                makeHttpPostRequest(`${BACKEND}${API}/d/candidates/generate_pdf/`, {interview_id: candidateInfo?.interviewId})
                                    .then((blob) => {
                                        FileSaver.saveAs(blob, `Report ${candidateInfo?.name}.pdf`);
                                    })
                                    .finally(() => {
                                        setIsLoading(false)
                                    })
                            }}
                            highlight
                        />}
                        <CommentSection
                            currentUser={{
                                currentUserId: user.id,
                                currentUserImg:
                                    `https://ui-avatars.com/api/name=${user.username}&background=${generateColor(user.username || "").replace('#', '')}`,
                                currentUserFullName: user.username
                            }}
                            logIn={{
                                loginLink: '',
                                signupLink: ''
                            }}
                            commentData={comments}
                            onSubmitAction={(comment) => {
                                makePostRequest(`${BACKEND}${API}/d/candidates/add_comment/`, {
                                    text: comment.text,
                                    interview_id: candidateInfo?.interviewId,
                                    parent: null
                                })
                                    .then((response) => {
                                        setComments(convertComments(response.comments))
                                    })
                            }}
                            onEditAction={(comment) => {
                                console.log('check edit, ', comment)
                                makePostRequest(`${BACKEND}${API}/d/candidates/edit_comment/`, {
                                    text: comment.text,
                                    comment_id: comment.comId
                                })
                                    .then((response) => {
                                        setComments(convertComments(response.comments))
                                    })
                            }}
                            onDeleteAction={(comment) => {
                                console.log('check delete, ', comment)
                                makePostRequest(`${BACKEND}${API}/d/candidates/delete_comment/`, {
                                    comment_id: comment.comIdToDelete
                                })
                                    .then((response) => {
                                        setComments(convertComments(response.comments))
                                    })
                            }}
                            currentData={(data) => {
                                console.log('curent data', data)
                            }}
                            removeEmoji={true}
                        /></div>}
                onClose={() => {
                    setCandidateInfo(undefined)
                }}
            />
        </>
    )
};

export default CandidateInfoPopup;