import styles from "./BaseLayout.module.css"
import Button from "../Buttons/Button";


const BaseLayout = ({ step_title, content_component, onPrevClick, onNextClick }) => {
    return (
        <div className={styles.main_content}>
            <div className={styles.smaller_box}>
                <div className={styles.header}>
                    <h1>{step_title}</h1>
                </div>
                <div className={styles.content_box}>
                    {content_component}
                </div>
                <div className={styles.buttons_container}>
                    {onPrevClick && <Button title={"Previous"} highlight={false} onClick={onPrevClick} />}
                    {onNextClick && <Button title={"Next"} highlight={true} onClick={onNextClick} />}
                </div>
            </div>
        </div>
    )
};

export default BaseLayout;
