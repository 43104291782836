import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import Row from './Row';
import Dropdown from '../General/Dropdown';

const useStyles = makeStyles({
  tableContainer: {
    boxShadow: 'none', // Removes shadow
    border: 'none', // Removes border
    height: '100%',
  },
});

function ComparisonTable({ candidates, data, onDropdownChangeFunc }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell style={{width: '5%'}}/>
            <TableCell style={{width: '17%'}}/>
            <TableCell style={{width: '39%'}}><Dropdown options={candidates} iconUrl="" id={'candidate1'} onChangeFunc={onDropdownChangeFunc} hint={<div>Select candidate...</div>}/></TableCell>
            <TableCell style={{width: '39%'}}><Dropdown options={candidates} iconUrl="" id={'candidate2'} onChangeFunc={onDropdownChangeFunc} hint={<div>Select candidate...</div>}/></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <Row key={index} question={row.question} response1={row.response1} response2={row.response2} hidden={row.hidden} isAI={row.isAI}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ComparisonTable;
