import React, {useContext, useEffect, useState} from 'react';
import Sidebar from 'react-sidebar';
import Menu from './Menu';
import styles from './Sidebar.module.css'
import Credentials from './Credentials';
import Logo from '../General/Logo';
import Dropdown from '../General/Dropdown';
import Cookies from "js-cookie";
import PositionContext from "../../contexts/position";
import {makePostRequest} from "../../api/submit";
import {API, BACKEND} from "../../constants";
import InterviewCreationWizardMenu from "./InterviewCreationWizardMenu";
import {AuthContext} from "../../auth/AuthContext";


// FIGURE OUT COLLAPSEABLE SIDEBAR LATER
/*

const mql = window.matchMedia(`(min-width: 800px)`);

class MySidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarDocked: mql.matches,
      sidebarOpen: false,
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  mediaQueryChanged() {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  }

  render() {
    const categories = [
      {
        label: 'Data',
        items: [
          { icon: <img src="/iconpeople.svg" alt="Candidates" />, label: 'Candidates', link: '' },
        ],
      },
      {
        label: 'Analysis',
        items: [
          { icon: <img src="/iconarrows.svg" alt="Comparisons" />, label: 'Comparisons', link: '' },
          { icon: <img src="/icon-chat-bubble.svg" alt="AI Chat" />, label: 'AI Chat', link: '' },
        ],
      },
    ];

    return (
    <Sidebar
        sidebar={<Menu categories={categories} />}
        open={this.state.sidebarOpen}
        docked={this.state.sidebarDocked}
        onSetOpen={this.onSetSidebarOpen}
        contentClassName={`${styles.sidebar} ${this.state.sidebarOpen ? styles['sidebar--open'] : ''}`}
        onMouseEnter={() => this.onSetSidebarOpen(true)}
        onMouseLeave={() => this.onSetSidebarOpen(false)}
      >
      </Sidebar>
    );
  }
}

export default MySidebar;
*/

function LeftSidebar({type, sidebarProps}) {

    const categories = [
        {
            label: 'data',
            items: [
                {icon: <img src="/iconpeople.svg" alt="Candidates"/>, label: 'Candidates', link: '/'},
            ],
        },
        {
            label: 'analysis',
            items: [
                {icon: <img src="/iconarrows.svg" alt="Comparisons"/>, label: 'Comparisons', link: '/Comparisons'},
                /*{icon: <img src="/-icon-chat-bubble.svg" alt="AI Chat"/>, label: 'AI Chat', link: ''},*/
            ],
        },
        {
            label: 'sandbox',
            items: [
                {icon: <img src="/iconarrows.svg" alt="New Interview"/>, label: 'New Interview', link: '/CreateInterview'},
            ],
        },
    ];

    const [positions, setPositions] = useState([]);
    const {position, setPosition } = useContext(PositionContext);


    let fetchPositions = function () {
        makePostRequest(`${BACKEND}${API}/fetch_positions/`, {})
            .then(response => {
                if (!response) return;

                setPositions((response?.positions || []).map((pos, index) => {
                    return {name: pos.name, id: pos.id, link: pos.link}
                }));
            })
    };

    useEffect(() => {
        fetchPositions();
    }, [])

    useEffect(() => {

    }, [position])

    let updatePosition = function (value, dropdownId) {
        setPosition(value !== ""?value:undefined);
    }

    return (
        <div className={styles.sideMenuDrawer}>
            {type === 'InterviewCreation' && <InterviewCreationWizardMenu {...sidebarProps} />}
            {type !== 'InterviewCreation' && <Menu categories={categories} positions={positions} position={position} updatePosition={updatePosition}/>}
            <Credentials></Credentials>
        </div>
    );
}

export default LeftSidebar
