import React from 'react';
import Modal from 'react-modal';
import Button from '../Buttons/Button.js'
import styles from './ConfirmationModal.module.css'

const ConfirmationModal = ({ isOpen, onRequestClose, onConfirm, confirmationQuestion, confirmationText, closeText, clarification }) => (
    <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Confirmation"
        style={{
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                zIndex: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            content: {
                position: 'relative',
                height: 'fit-content',
                width: '300px',
                right: 'auto',
                bottom: 'auto',
                backgroundColor: 'white',
                borderRadius: '22px',
                padding: '30px',
                zIndex: 101,
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
            }
        }}
    >
        <h3 className={styles.question}>{confirmationQuestion}</h3>
        <p className={styles.clarification}>{clarification}</p>
        <div className={styles.action_btn_container}>
            <Button onClick={onConfirm} title={confirmationText} highlight={false} />
            <Button onClick={onRequestClose} title={closeText} highlight={true} />
        </div>

    </Modal>
);

export default ConfirmationModal;
