import chroma from 'chroma-js';

const generateColor = (name) => {
  // Generate a hash from the name
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert the hash to a hue value within the specified range (173 to 325)
  const hue = 173 + Math.abs(hash%152);

  // Set saturation and lightness for a lighter and more vibrant color
  const saturation = 90; // 90% saturation for more vibrant colors
  const lightness = 85;  // 90% lightness for lighter colors

  return chroma.hsl(hue, saturation / 100, lightness / 100).hex();
};

export { generateColor };


const generateTextColor = (backgroundColor) => {
    // Extract the hue, saturation, and lightness from the background color
    const [hue] = chroma(backgroundColor).hsl();
  
    // Set saturation and lightness for a much darker color
    const saturation = 85; // 80% saturation to maintain vibrancy
    const lightness = 25;  // 25% lightness for a darker shade
  
    return chroma.hsl(hue, saturation / 100, lightness / 100).hex();
  };
  
  export { generateTextColor };