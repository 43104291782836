import styles from "./Wizard.module.css";
import NavigationItem from "../Wizard/NavigationItem";
import Button from "../Buttons/Button";
import React from "react";
import InterviewCreationWizardMenu from "../Sidebar/InterviewCreationWizardMenu";
import LeftSidebar from "../Sidebar/Sidebar";

const   BaseLayout = ({steps, mainContent, onPrevClick, onCurrentClick, onNextClick, jumpToStep, setIsBackPopupOpen}) => {
    return (
        <div className={styles.page_wrapper}>
            <LeftSidebar type={'InterviewCreation'} sidebarProps={{steps: steps, onCurrentClick: onCurrentClick, jumpToStep: jumpToStep, setIsBackPopupOpen: setIsBackPopupOpen}}/>
            <div className={styles.right_layout}>
                <div className={styles.main_wrapper}>
                    {mainContent}
                    <hr/>
                    <div className={styles.wizard_controls}>
                        {onPrevClick &&
                            <Button title={"Previous"} highlight={false} onClick={() => onPrevClick(jumpToStep)}/>}
                        {onNextClick &&
                            <Button title={"Next"} highlight={true} onClick={() => onNextClick(jumpToStep)}/>}
                    </div>
                </div>
            </div>
        </div>)
}

export default BaseLayout;