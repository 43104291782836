import React, {useContext} from 'react';
import RoleplayContext from "../../contexts/roleplay";
import {processRoleplayReply} from "../../api/submit";
import styles from './MessageParser.module.css'

const MessageParser = ({children, processMessage, actions}) => {
    const parse = (message) => {
        if (message === '') return;
        processMessage(message)
    };

    return (
        <div className={styles.wrapper}>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    parse: parse,
                    actions: {},
                });
            })}
        </div>
    );
};

export default MessageParser;