import React, {useContext, useEffect, useState} from 'react';
import styles from './Dropdown.module.css';
import {ThemeProvider, InputLabel, Select, MenuItem, makeStyles, FormControl, createTheme} from '@material-ui/core'

const theme = createTheme({
  typography: {
    fontFamily: "var(--font-nunito)",
  },
});

const useStyles = makeStyles((theme) => ({
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
}));


function Dropdown({options, iconUrl, color, onChangeFunc, id, hint, value, height, width}) {
    const classes = useStyles();
    const [currentValue, setCurrentValue] = useState(value || "");

    useEffect(() => {
        onChangeFunc(currentValue, id);
    }, [currentValue])
    return (
        <ThemeProvider theme={theme}>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">{hint}</InputLabel>
                <Select
                    labelId={id}
                    id={id}
                    value={currentValue}
                    label={hint}
                    onChange={(e) => setCurrentValue(e.target.value)}
                >
                    {options.map(option => (
                        <MenuItem value={option.id}>{option.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </ThemeProvider>
    );
}

export default Dropdown;
