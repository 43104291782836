import React, {useEffect, useState} from "react";
import styles from "../../pages/InterviewCreation.module.css";
import Button2 from "../Buttons/Button2";
import Card from "./Card";
import {makePostRequest} from "../../api/submit";
import {API, BACKEND} from "../../constants";

const Qualifications = ({setValue, value, setIsLoading}) => {
    const options = {
        mustHave: 'Must Have',
        niceToHave: 'Nice To Have'
    }

    const [cards, setCards] = useState(value?.qualifications || []);

    const addCard = () => {
        // if an empty card was already added
        if (cards.some((item) => item.prompt.trim() === '')) {
            return;
        }
        const newCard = {
            shortName: "",
            prompt: ""
        }
        setCards([newCard, ...cards])
    }

    const generateQualification = () => {
        setIsLoading(true)
        makePostRequest(`${BACKEND}${API}/d/interview_creation/generate_qualification/`, value)
            .then(response => {
                setIsLoading(false)
                const qualificationPrompt = response.qualification;
                const shortName = response.shortName;
                const newCard = {
                    shortName: shortName || "",
                    prompt: qualificationPrompt
                }
                setCards([newCard, ...cards])
            })

    }

    const deleteCard = (index) => {
        const updatedCards = [...cards];
        updatedCards.splice(index, 1);
        setCards(updatedCards);
    }

    const updateDescription = (index, value) => {
        const updatedCards = [...cards];
        updatedCards[index].prompt = value
        setCards(updatedCards);
    };

    const updateDropdown = (index, value) => {
        const updatedCards = [...cards];
        updatedCards[index].dropDown = value
        setCards(updatedCards);
    };

    useEffect(() => {
        setValue((prev) => {
            return {
                ...prev, qualifications: cards.filter((card) => {
                    return card.prompt !== "";
                })
            }
        })
    }, [cards])

    return (<>
        <div className={styles.qualification_controls}>
            <Button2
                icon={<img src={"/Plus.svg"}/>}
                title={"Add Qualification"}
                onClick={addCard}
            />
            <Button2
                icon={<img src={"/Sparkling2.svg"}/>}
                title={"Suggest Qualification"}
                onClick={generateQualification}
            />
        </div>
        {cards.map((card, index) => {
            return <Card
                key={index}
                cardInfo={card}
                onChange={(e) => updateDescription(index, e.target.value)}
                onDelete={(e) => deleteCard(index)}
                dropdownShow={false}
                dropdownProps={{
                    options: Object.keys(options).map((key) => {
                        console.log(options, key, options[key])
                        return {id: key, name: options[key]}
                    }),
                    id: `${index}`,
                    onChangeFunc: (e, id) => updateDropdown(id, e?.value || undefined),
                    value: {value: card.dropDown, label: options?.[card.dropDown]}
                }}
            />
        })}
    </>)
}

export default Qualifications