import React from 'react';
import styles from './TextCell.module.css'
import Tooltip from "@material-ui/core/Tooltip";

const GeneralCell = ({sortValue, filterValue, cellProps, child, tooltipText}) => {
    if (tooltipText === undefined)
        tooltipText = '';
    return (
        <Tooltip title={tooltipText}>
            <div>
                {child}
            </div>
        </Tooltip>
    );
};

export default GeneralCell;
