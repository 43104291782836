import React, {useState, createContext} from 'react';
import useStateCallback from "../modules/stateCallback"


const InterviewCreationContext = createContext();

export const InterviewCreationProvider = ({children}) => {
    const [errors, setErrors] = useStateCallback({});

    return (
        <InterviewCreationContext.Provider value={{errors, setErrors}}>
            {children}
        </InterviewCreationContext.Provider>
    );
};

export default InterviewCreationContext;