function formatEuropeanDate(timestamp, includeSeconds=false) {
    const date = new Date(timestamp * 1000); // Convert to milliseconds

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    if (includeSeconds === true)
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    else
        return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export {formatEuropeanDate}