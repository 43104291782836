import React, {useEffect, useState} from 'react';
import ApplicationTable from "./ApplicationTable";
import CandidateHeader from "../../components/CandidatePage/CandidateHeader";
import styles from "./Dashboard.module.css";
import {makePostRequest} from "../../api/submit";
import {API, BACKEND} from "../../constants";
import {createRecommendedGA4Event} from "../../api/ga4";

const applications = [
    {
        id: 1,
        company: 'Taita',
        positionName: 'Software Engineer',
        dateStarted: '2024-11-05',
        status: 'In Progress',
    },
    {
        id: 2,
        company: 'Taita',
        positionName: 'Product Designer',
        dateStarted: '2024-11-01',
        status: 'Completed',
    },
    {
        id: 3,
        company: 'Taita',
        positionName: 'Data Analyst',
        dateStarted: '2024-10-29',
        status: 'Not Started',
    },
];

const Dashboard = () => {
    const [interviews, setInterviews] = useState([]);

    useEffect(() => {
        makePostRequest(`${BACKEND}${API}/get_started_interviews/`, {})
                .then((response) => {
                    if (response?.error) {
                        throw new Error(response.error);
                    }
                    const _interviews = response?.interviews || [];
                    setInterviews(_interviews);
                })
    }, [])

    return (
        <div className={styles.page_content}>
            <CandidateHeader/>
            <ApplicationTable interviews={interviews}/>
            <p>If the interview you were invited to does not show up here, check your email for the invitation link.</p>
        </div>
    )
};

export default Dashboard;
