import React, {useContext, useEffect, useState} from 'react'
import styles from "./Interview.module.css"
import Interview from "./Interview";
import InterviewOutro from "./InterviewOutro";
import {makePostRequest} from "../../api/submit";
import {API, BACKEND} from "../../constants";
import InterviewContext from "../../contexts/interview";
import StepZilla from "react-stepzilla";
import InterviewIntro from "./InterviewIntro";

const InterviewBase = () => {
    const [questionExists, setQuestionExists] = useState(true)
    const [positionTexts, setPositionTexts] = useState({})
    const [displayComponent, setDisplayComponent] = useState()
    const {accessCode} = useContext(InterviewContext)

    useEffect(() => {
        if (accessCode === undefined) return
        makePostRequest(`${BACKEND}${API}/get_position_texts/`, {interview_access_code: accessCode}).then(response => {
            setPositionTexts(response?.texts || {})
        })
    }, [accessCode])

    useEffect(() => {
        if (questionExists === true) {
            setDisplayComponent()
        } else {
            setDisplayComponent(<InterviewOutro positionTexts={positionTexts}/>)
        }
    }, [questionExists])

    const steps = [
        {name: 'Interview Intro', component: <InterviewIntro/>},
        {name: 'Interview', component: <Interview setQuestionExists={setQuestionExists}/>},
        {name: 'Interview Outro', component: <InterviewOutro positionTexts={positionTexts}/>},
    ];

    return (<div className={styles.interview_page}>
        <StepZilla
                steps={steps}
                startAtStep={0}
                showNavigation={false}
                dontValidate={true}
                stepsNavigation={false}
                showSteps={false}
                preventEnterSubmission={true}
            />
    </div>)
}

export default InterviewBase
