import {Store} from "react-notifications-component";

export const formatStr = function (S) {
    return (S || '').trim().replace(/^[\r\n\t\s]+|[\r\n\t\s]+$/g, '').replace(/\n/g, '<br>')
}

export const formatJsonStrings = function (obj) {
    // Base case: if obj is not an object or is null, return it as is
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    // If obj is an array, recursively format each element
    if (Array.isArray(obj)) {
        return obj.map((item) => formatJsonStrings(item));
    }

    // If obj is an object, recursively format its string properties
    if (typeof obj === 'object') {
        const formattedObj = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                formattedObj[key] =
                    typeof obj[key] === 'string' ? formatStr(obj[key]) : formatJsonStrings(obj[key]);
            }
        }
        return formattedObj;
    }

    return obj;
}


export const replaceUnderscoresAndCapitalize = function (str) {
    // Split the string by underscores
    let words = str.split('_');

    // Capitalize each word and join them with spaces
    let capitalizedWords = words.map(word => {
        // Capitalize the first letter of each word
        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the words with spaces
    return capitalizedWords.join(' ');
}

export const getAIMatchSortValue = function (text) {
    const textLower = text.toLowerCase();
    if (textLower === 'completed') {
        return 0
    } else if (textLower === 'in progress') {
        return -1
    } else if (textLower === 'not started') {
        return -2
    }
}

export const copyTextToClipboard = function (text) {
    navigator.clipboard.writeText(text).then(() => {
        Store.addNotification({
            title: "Text copied to clipboard!",
            type: "success",
            insert: "top",
            container: "top-right",
            dismiss: {
                duration: 3000,
                showIcon: true
            }
        });
    })
}





