import React, {useContext, useEffect, useState} from 'react';
import styles from './Question_in_Selection.module.css';

const Button = ({title, onClickFunc, isActive, id}) => {
    return (
        <button
            className={`${styles.conflictResolutionContainer} ${isActive ? styles.selected : ''}`}
            onClick={() => {
                onClickFunc(id)
            }}
        >
            <div className={styles.conflictResolution}>{title}</div>
        </button>
    );
};

export default Button;
