import React from 'react';
import {Link} from 'react-router-dom'; // import Link from react-router-dom
import styles from './Menu.module.css';
import Logo from "../General/Logo";
import Dropdown from "../General/DropdownMaterial";
import {createRecommendedGA4Event} from "../../api/ga4";
import {copyTextToClipboard} from "../../utils/common";


const MenuItem = ({icon, label, link}) => {
    return (
        <Link to={link} className={`${styles['menu-item']}`} id={`menu-item-${label.toLowerCase().replaceAll(' ', '_')}`}>
            <div className={styles['menu-item-icon']}>
                {icon}
            </div>
            <span className={styles['menu-item-label']}>
        {label}
      </span>
        </Link>
    );
};

const MenuCategory = ({label, items}) => {
    return (
        <div className={styles['menu-category-title']}>
            <h3>{label}</h3>
            {items.map((item, index) => (
                <MenuItem key={index} {...item} />
            ))}
        </div>
    );
};

const Menu = ({categories, positions, updatePosition, position}) => {
    return (
        <div className={styles.menuTop}>
            <Logo full={true}></Logo>
            <div className={styles.dropdownWrapper}>
                <Dropdown options={positions} iconUrl="/vector.svg" onChangeFunc={updatePosition}
                          value={position}
                          id={'jobSelection'}
                          hint={<div>Positions...</div>}/>
                {position &&
                    <div className={styles.copyToClipboard} onClick={() => {
                        createRecommendedGA4Event('copy_interview_link', {'label': 'Sidebar', 'category': 'HR Button Click'});
                        copyTextToClipboard(positions.find(element => element.id === position)?.link)
                    }}>Copy sign-up link</div>}
            </div>
            <div className={styles.menu}>
                {categories.map((category, index) => (
                    <MenuCategory key={index} {...category} />
                ))}
            </div>
        </div>
    );
};

export default Menu;
