import React, {useContext} from 'react';
import styles from './Header.module.css';
import Button from './Question_in_Selection';
import PositionContext from "../../contexts/position";

const Header = ({boxData}) => {
    const {handleHeaderButtonClick} = useContext(PositionContext);
    return (
        <div className={styles.stickyTop}>
            <h1 className={styles.pageTitle}>compare candidates using ai</h1>
            <div className={styles.questionSelectionWrapper}>
                {boxData.map((element, index) => (
                    <Button title={element.question.summaryText} isActive={!element.hidden} id={{q_type: element.question.q_type, q_num: element.question.q_number}} onClickFunc={handleHeaderButtonClick}/>
                ))}
            </div>
        </div>
    );
};

export default Header;
