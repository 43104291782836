import React, {useState, useEffect, useContext} from 'react';
import {handleLogout} from "../utils/auth";


function Logout() {
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        handleLogout().then(() => {
            setSuccess(true)
        })
    }, [])
    return (<>{ success ? <h1>Logged out</h1> : <h1>Logging out...</h1> }</>)
}

export default Logout