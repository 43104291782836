import Avatar from 'react-avatar';

const SystemMessage = ({ state, message }) => {
    return (
        <p>SYSTEM MESSAGE: {message.message}</p>
    );
};

class Config {
    constructor(conf) {
        const initialMessages = (conf?.initialMessages) ? conf.initialMessages : [];
        this.botName = "Roleplay Bot";
        this.initialMessages = initialMessages;
        this.customComponents = {
            // // Replaces the default header
            // header: () => <div style={{
            //     backgroundColor: '#8600CE',
            //     color: 'white',
            //     padding: "5px",
            //     borderRadius: "3px"
            // }}>{conf.headerMessage}</div>
            // Replaces the default bot avatar
            botAvatar: (props) => <Avatar value="Bot" color={"#131138"} size={"50px"} round={true}/>,
            // Replaces the default bot chat message container
            // botChatMessage: (props) => <CustomBotMessage />,
            // Replaces the default user icon


            // Viktor, insert user name here
            userAvatar: () => <Avatar name={(conf?.username) ? conf.username : "User You"} color={"#8600CE"} size={"50px"} round={true}/>,
            // Replaces the default user chat message
            //userChatMessage: (props) => <MyCustomUserChatMessage {...props} />
        };
        this.customMessages = {
            system: (props) => {
                return <SystemMessage {...props} message={props.state.messages.find(msg => (msg.payload === props.payload))} />
            },
        }
    }

}

export default Config