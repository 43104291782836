import styles from "./VideoInterviewCard.module.css";
import React, {useEffect, useRef, useState} from "react";
import Dropdown from "../General/Dropdown";
import Button from "../Buttons/Button";

const VideoInterviewCard = ({
                                cardInfo,
                                moveUp,
                                moveDown,
                                onChange,
                                timeLimitDropdownProps,
                                triesDropdownProps,
                                onDelete,
                                qualificationsArray,
                            }) => {

    const [isEditing, setIsEditing] = useState(cardInfo.prompt === "");
    const [autoFocus, setAutoFocus] = useState(false);
    const textAreaRef = useRef(null);

    const handleDoubleClick = () => {
        setIsEditing(true);
        setAutoFocus(true)
        setTimeout(() => {
            if (textAreaRef.current) {
                textAreaRef.current.focus();

                // Set the selection range to the end of the text
                textAreaRef.current.setSelectionRange(
                    textAreaRef.current.value.length,
                    textAreaRef.current.value.length
                );
            }
        }, 0);
    };

    const handleBlur = () => {
        if (cardInfo.prompt !== "") {
            setIsEditing(false)
        }
    };


    useEffect(() => {
        if (cardInfo.prompt === "") {
            setIsEditing(true)
            setAutoFocus(false)
        }
    }, [cardInfo.prompt])

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <p className={styles.short_name}>{cardInfo.shortName}</p>
                {isEditing ? (
                    <textarea
                        className={styles.description}
                        type="text"
                        ref={textAreaRef}
                        value={cardInfo.prompt}
                        onChange={onChange}
                        onBlur={handleBlur}
                        autoFocus={autoFocus}
                        placeholder={"enter a new interview question here..."}
                    />
                ) : (
                    <div className={styles.description} onClick={handleDoubleClick}>{cardInfo.prompt}</div>
                )}
                <div className={styles.selectors_wrapper}>
                    <div className={styles.time_wrapper}>
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_637_1015)">
                                <path
                                    d="M17.7101 0H10.1617V2.57143H17.7101V0ZM12.6778 16.7143H15.1939V9H12.6778V16.7143ZM22.7801 8.21571L24.5665 6.39C24.0255 5.73429 23.4343 5.11714 22.7926 4.57714L21.0062 6.40286C19.0562 4.80857 16.603 3.85714 13.9359 3.85714C7.68328 3.85714 2.61328 9.03857 2.61328 15.4286C2.61328 21.8186 7.6707 27 13.9359 27C20.201 27 25.2584 21.8186 25.2584 15.4286C25.2584 12.7029 24.3275 10.1957 22.7801 8.21571ZM13.9359 24.4286C9.06715 24.4286 5.12941 20.4043 5.12941 15.4286C5.12941 10.4529 9.06715 6.42857 13.9359 6.42857C18.8046 6.42857 22.7423 10.4529 22.7423 15.4286C22.7423 20.4043 18.8046 24.4286 13.9359 24.4286Z"
                                    fill="#777777"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_637_1015">
                                    <rect width="27" height="27" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <Dropdown {...timeLimitDropdownProps} />
                    </div>
                    <div className={styles.tries_wrapper}>
                        <img src={"/Reset.png"}/>
                        <Dropdown {...triesDropdownProps} />
                    </div>

                </div>
                {qualificationsArray && <div>
                    <p className={styles.gray_text}>Qualifications assessed: </p>
                    {qualificationsArray.map((qualification, index) => {
                        return <p
                            className={styles.gray_text + " " + styles.list_element}>{qualification?.shortname || ""}</p>
                    })
                    }
                </div>}
                <div className={styles.navigation}>
                    <Button title={'Down'} onClick={moveDown} highlight={false}/>
                    <Button title={'Up'} onClick={moveUp} highlight={false}/>
                </div>
            </div>
            <div className={styles.dot_menu}>
                <Button title={'Delete'} onClick={onDelete} highlight={false}/>
            </div>
        </div>
    )
}

export default VideoInterviewCard;