import React, {useContext, useEffect, useState} from 'react';
import styles from './Button.module.css';

const Button = ({title, onClick, highlight, className, disabled=false}) => {
    return (
        <button
            className={`${styles.button} ${highlight ? styles.selected : ''} ${className || ''}`}
            onClick={!disabled?onClick:undefined}
        >
            {title}

        </button>
    );
};

export default Button;
