import styles from "./Authentication.module.css";
import React from "react";
import Form from "./Form";
import {LOGIN_URL, REGISTER_URL} from "../../constants";
import ProviderList from "../../socialaccount/ProviderList";
import {useSearchParams} from "react-router-dom";


const Authentication = ({formQuestions, formValidate, formHandleSubmit, isLoginPage, formError}) => {
    const headerText = isLoginPage ? "welcome back!" : "sign up!";
    const formSubmitTitle = isLoginPage ? "Login" : "Sign Up";
    const [searchParams, setSearchParams] = useSearchParams();

    return (<div className={styles.login_page}>
            <div className={styles.login_part}>
                <div className={styles.login_section}>
                    <div className={styles.login_form}>
                        <p className={styles.welcome_back}>{headerText}</p>
                        <p className={styles.gray_text}>Spend less time screening and more time building connections</p>
                        <Form questions={formQuestions} handleSubmit={formHandleSubmit} validate={formValidate}
                              submitTitle={formSubmitTitle} formError={formError}/>
                    </div>
                </div>
                <div>
                    <h2>Or use a third-party</h2>
                    <ProviderList callbackURL={`/account/provider/callback?${searchParams.toString()}`}/>
                </div>
                {isLoginPage &&
                    <p className={styles.login_footer}>New to taita.io? <a href={`${REGISTER_URL}?${searchParams.toString()}`}
                                                                           className={styles.sign_up_link}>Get
                        Started</a>
                    </p>}
                {!isLoginPage &&
                    <p className={styles.login_footer}>Have an account? <a href={`${LOGIN_URL}?${searchParams.toString()}`}
                                                                           className={styles.sign_up_link}>Log In</a>
                    </p>}
            </div>
            <div className={styles.ad_part}>
                <div className={styles.text_content}>
                    <p className={styles.main_text}>At EDUKOHT, we found Taita's automated interviewing and AI-enhanced
                        analytics to be a game-changer. Their state-of-the-art AI technology saved us over 20 hours per
                        hire
                        while providing a level of interview functionality that surpasses human capability. The AI-based
                        analytics eliminate guesswork from the selection process, allowing me to dedicate more time to
                        building meaningful relationships with candidates. </p>
                    <p className={styles.signature}>— Georgi Sokolov, Co-founder of EDUKOHT</p>
                </div>
                <div className={styles.image_content}>
                    <img src="/Dashboard.png" alt="Screenshot"></img>
                </div>
            </div>
        </div>
    )
}

export default Authentication