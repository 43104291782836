import Authentication from "../components/General/Authentication"
import React, {useContext, useEffect, useState} from "react";
import {makePostRequest} from "../api/submit";
import {API, BACKEND, CANDIDATE, CLIENT, LOGIN_URL} from "../constants";
import {useSearchParams} from "react-router-dom";
import {AuthContext, AuthRole} from "../auth/AuthContext";


const Login = ({role, referTo}) => {
    const [formError, setFormError] = useState('');
    const {user, setUser} = useContext(AuthContext);
    const [redirectNeeded, setRedirectNeeded] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        if (redirectNeeded !== true || user === undefined) return
        if (user?.role === AuthRole.CLIENT) {
            window.location.href = decodeURIComponent(searchParams.get("referrer") || `${CLIENT}/`)
            setRedirectNeeded(false)
        } else if (user?.role === AuthRole.CANDIDATE) {
            window.location.href = decodeURIComponent(searchParams.get("referrer") || `${CANDIDATE}/d1`)
            setRedirectNeeded(false)
        }
    }, [user, redirectNeeded])

    const formHandleSubmit = (answers) => {
        makePostRequest(`${BACKEND}${API}/login/`, {
            username: answers.username,
            password: answers.password
        }).then(response => {
            const status = response._metadata.status;
            if (status === 200) {
                makePostRequest(`${BACKEND}${API}/fetch_user/`, {})
                    .then(response => {
                        setUser(response)
                        setRedirectNeeded(true)
                    })
            } else if (status === 401) {
                setFormError('Invalid Username or/and Password')
            } else {
                setFormError('Something went wrong. Please try again later')
            }
        })
    }

    const formValidate = (answers, setErrors) => {
        const newErrors = {};
        questions.forEach(({id, type, required}) => {
            if (!answers[id] && required) {
                newErrors[id] = 'This field is required';
            } else if (type === 'email' && !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,12}$/.test(answers[id])) {
                newErrors[id] = 'Invalid email format';
            }
        });
        setErrors(newErrors);
        return !Object.values(newErrors).length;
    }

    const questions = [
        {
            id: "username",
            label: "Email or Username",
            type: "text",
            required: true
        },
        {
            id: "password",
            label: "Password",
            type: "password",
            required: true
        }
    ]
    return (<Authentication formQuestions={questions} formValidate={formValidate} formHandleSubmit={formHandleSubmit}
                            isLoginPage={true} formError={formError}/>)
};

export default Login;