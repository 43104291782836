import React, { useContext, useEffect, useState } from 'react';
import styles from './StopRecordingBtn.module.css';

const StopRecordingBtn = ({ title, onClick }) => {

  return (
    <div className={styles.button_wrapper}>
      <button
        className={styles.button}
        onClick={onClick}
      >
      </button>
    </div>
  );
};

export default StopRecordingBtn;
