import React, {createContext, useContext} from 'react';
import {AuthContext, AuthRole} from "../auth/AuthContext";
import {LOGIN_URL} from "../constants";
import Button from "@mui/material/Button";

const CandidateContext = createContext();

function AccessDenied() {
    return <div>
        <div>You do not have access to this page...</div>
        <Button variant={"contained"} onClick={() => {window.location.href = `${LOGIN_URL}?referrer=${encodeURIComponent(window.location.pathname)}${encodeURIComponent(window.location.hash)}`}} >Log In</Button>
    </div>
}

export const CandidateProvider = ({children}) => {
    const {user} = useContext(AuthContext);


    return (
        <CandidateContext.Provider>
            {(user?.role === AuthRole.CLIENT || user?.role === AuthRole.CANDIDATE) ? children : <AccessDenied/>}
        </CandidateContext.Provider>
    );
};

export default CandidateContext;