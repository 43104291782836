import React, {useContext} from 'react';
import styles from "./CandidateHeader.module.css";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {AuthContext, AuthRole} from "../../auth/AuthContext";

const CandidateHeader = () => {
    const {user} = useContext(AuthContext);

    return <div className={styles.header}>
        <div className={styles.logo_block}>
            <img src="/jobBoard/TaitaBlobFullPurple1.png" alt=""/>
        </div>


        <div className={styles.account_menu}>
            <Link to={"/d1"}><Button variant="contained" className={styles.generic_button}>My Jobs</Button></Link>
            {/*<Link to={"/d2"}><Button variant="contained" className={styles.generic_button}>Search Jobs</Button></Link>*/}
            {user?.role === AuthRole.CLIENT && <Button variant="outlined" className={styles.generic_button}
                     onClick={() => window.location.href = '/client/dashboard/#/'}>Employer View</Button>}
            <Button variant="outlined" className={styles.generic_button} onClick={() => window.location.href = '/start/#/logout'}>Log out</Button>
            <div className={styles.generic_avatar}>
                <img src="/jobBoard/AvatarPlaceholder.svg" alt=""/>
            </div>
        </div>
    </div>
};

export default CandidateHeader;
