import ReactGA from "react-ga4";
import {GA4} from "../constants";
ReactGA.initialize(GA4);

function createCustomGA4Event(category, action, label) {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    });
}

function createRecommendedGA4Event(name, params) {
    ReactGA.event(name, params);
}

export {createCustomGA4Event, createRecommendedGA4Event}