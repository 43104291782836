import React from 'react';
import {useContext, useEffect, useState} from "react";
import styles from "./JobBoard.module.css";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import SearchBar from "material-ui-search-bar";
import CandidateHeader from "../../components/CandidatePage/CandidateHeader";

const JobBoard = () => {

    const [bar1Value, setBar1Value] = useState('');


    return <div className={styles.page_content}>
        <CandidateHeader/>
        <div className={styles.main_content}>
            <div className={styles.search_block}>
                <SearchBar
                    value={bar1Value}
                    onChange={(newValue) => setBar1Value(newValue)}
                    onRequestSearch={() => console.log(bar1Value)}
                />

                <div className={styles.bar}>bar2</div>
                <Button variant="contained">Text</Button>

                {/*    className={styles.search_button}*/}
            </div>
        </div>

    </div>;
};

export default JobBoard;
