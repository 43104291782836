import {useConfig} from '../auth'
import {redirectToProvider} from '../libs/allauth'
import Button from '../components/Buttons/Button'
import GoogleOneTap from "./GoogleOneTap";

export default function ProviderList(props) {
    const config = useConfig()
    const providers = config.data.socialaccount.providers
    if (!providers.length) {
        return null
    }
    return (
        <>
            {/*<GoogleOneTap process={props.process} />*/}
            <ul>
                {providers.map(provider => {
                    console.log("provider", provider)
                    return (
                        <li key={provider.id}>
                            <Button
                                onClick={() => redirectToProvider(provider.id, props.callbackURL, props.process)}
                                title={provider.name}
                                highlight
                            />
                        </li>
                    )
                })}
            </ul>
        </>
    )
}