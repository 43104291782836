import {TextField} from "@material-ui/core";
import Button from "../Buttons/Button";
import styles from "./Form.module.css";
import React, {useEffect} from "react";

const Form = ({questions, validate, handleSubmit, submitTitle, formError}) => {
    const [answers, setAnswers] = React.useState({});
    const [errors, setErrors] = React.useState({});
    const [isSubmitClicked, setIsSubmitClicked] = React.useState(false);

    const handleChange = (id) => (e) => {
        setAnswers({...answers, [id]: e.target.value});
    }

    return (<form>
        {formError && <p className={styles.global_error}>{formError}</p>}
        {questions.map((question) => (
            <TextField
                key={question.id}
                id={question.id}
                label={question.label}
                type={question.type}
                onChange={handleChange(question.id)}
                fullWidth
                margin="normal"
                required={question.required || true}
                error={isSubmitClicked && errors[question.id] !== undefined}
                helperText={errors[question.id]}
            />))}

        <Button title={submitTitle} className={styles.button} highlight onClick={(e) => {
            e.preventDefault()
            setIsSubmitClicked(true)
            if (validate(answers, setErrors)) {
                handleSubmit(answers);
            }
        }}>Login</Button>
    </form>)
}

export default Form