import React, {useState} from "react";
import Popup from "../../components/Popup/Base";
import {TextField} from "@material-ui/core";
import {makePostRequest} from "../../api/submit";
import {API, BACKEND} from "../../constants";


const CopyCandidatesPopup = ({isOpen, setIsOpen}) => {
    const [positionFrom, setPositionFrom] = useState(0)
    const [positionTo, setPositionTo] = useState(0)
    const [dialogue, setDialogue] = useState()

    return (
        <Popup
            isOpen={isOpen}
            content={<div>
                <TextField
                    id="position_from"
                    label="Position ID (from)"
                    value={positionFrom}
                    type="number"
                    onChange={(event) => setPositionFrom(event.target.value)}
                />
                <TextField
                    id="position_to"
                    label="Position ID (to)"
                    value={positionTo}
                    type="number"
                    onChange={(event) => setPositionTo(event.target.value)}
                />
                {dialogue && <p>{dialogue}</p>}
            </div>}
            actionButtonText={"Copy Candidates"}
            onSubmit={() => {
                setDialogue("")
                makePostRequest(`${BACKEND}${API}/d/candidates/copy_candidates/`, {
                    position_id_from: positionFrom,
                    position_id_to: positionTo,
                }).then((response) => {
                    if (response?.error !== undefined) throw new Error(response.error);
                    setDialogue("Success!")
                }).catch((error) => {
                    console.log(error)
                    setDialogue(error.toString() || "Unknown Error Happened")
                })
            }}
            onClose={() => {
                setIsOpen(false)
                setDialogue("")
            }}
        />
    )
};

export default CopyCandidatesPopup;