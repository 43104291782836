import React from 'react';
import Select from 'react-select';

const Status = ({initialStatus, onChangeStatus}) => {
    const options = [
        {value: 'undefined', label: 'Undefined', color: '#777777'},
        {value: 'rejected', label: 'Rejected', color: '#F44336'},
        {value: 'shortlisted', label: 'Shortlisted', color: '#4CAF50'},
        {value: 'accepted', label: 'Accepted', color: '#2196F3'},
    ];

    const defaultValue = options.find(option => option.value === initialStatus) || options[0];

    const handleChange = (selectedOption, e) => {
        // Trigger the change in the backend
        onChangeStatus(selectedOption.value);
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: '150px',
            height: 'fit-content',
            borderRadius: '8px',
            border: state.isFocused ? '1px solid #8600CE' : '1px solid #BBB',
            background: '#FFF',
            fontFamily: 'Nunito, sans-serif',
            fontSize: '14px',
            color: '#333333',
            boxShadow: 'none',
            '&:hover': {borderColor: '#8600CE'},
        }),
        option: (provided, state) => ({
            ...provided,
            paddingLeft: '32px',
            position: 'relative',
            color: state.isSelected ? '#333' : null,
            backgroundColor: state.isFocused ? '#eee' : state.isSelected ? '#FFCDFF' : state.isSearchable ? '#FFCDFF' : null,
            '&::before': {
                content: '""',
                position: 'absolute',
                left: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '18px',
                height: '18px',
                borderRadius: '50%',
                backgroundColor: state.data.color,
            },
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: "0",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            paddingLeft: '32px',
            position: 'relative',
            color: '#333333',
            width: "100%",
            fontWeight: '700;',
            '&::before': {
                content: '""',
                position: 'absolute',
                left: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '18px',
                height: '18px',
                borderRadius: '50%',
                backgroundColor: state.data.color,
            },
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#BBB',
        }),
        menu: (provided) => ({
            ...provided,
            width: '150px',
            color: '#333',
            fontFamily: 'Nunito, sans-serif',
            fontSize: '14px',

        }),
    };

    return (
        <div onClick={(event) => {event.stopPropagation()}}>
            <Select
                options={options}
                defaultValue={defaultValue}
                styles={customStyles}
                onChange={handleChange}
                isSearchable={false}
                menuPortalTarget={document.body}
            />
        </div>
    );
};

export default Status;
