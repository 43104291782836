import BaseLayout from "../../components/Wizard/BaseLayout";
import styles from "../InterviewWizard.module.css";
import DOMPurify from "dompurify";
import React from "react";

const DEFAULT_POSITION_OUTRO_TEXT = "To begin your video interview, please follow these instructions:<br><b>Understanding the Format:</b><ul><li>You will be asked several questions during this interview.</li><li>Each question will be displayed at the top of the screen.</li></ul><b>Preparation Phase:</b><ul><li>Take your time to think about your response to each question.</li><li>Utilize the provided note field below each question for your personal brainstorming. Remember, the notes are private and not saved or shared.</li></ul><b>Recording Your Answer:</b><ul><li>When ready, click the 'Record' button to start recording your answer.</li><li>Responses typically range from 1 to 3 minutes, varying with the complexity of the question.</li></ul><b>Rerecording Option:</b><ul><li>If you wish to rerecord your answer, you can do so. The allowable number of attempts is displayed in the bottom right corner of the screen.</li></ul><b>Evaluation Process:</b><ul><li>Your video responses will be reviewed both by our AI system and human recruiters.</li><li>Our assessment focuses on your overall fit for the role, not just specific keywords in your answers.</li></ul><b>Support and Feedback:</b><ul><li>For any technical issues or feedback, please reach out to us at hello@taita.io.</li></ul><b>We encourage you to enjoy the process and express yourself freely. Best of luck in your interview!</b>"

const InterviewIntro = (props) => (
        <BaseLayout
            step_title={"Welcome to Taita's Video Interview Platform!"}
            content_component={
                <div>
                    <p
                        className={styles.text}
                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props?.positionInfo?.messages?.['general.outro'] || DEFAULT_POSITION_OUTRO_TEXT)}}
                    />
                    <div style={{position: 'relative', paddingBottom: '46.875%', height: '0',}}>
                        <iframe
                            src="https://www.loom.com/embed/3fdffb31e2d94cdebec28e76f8682284?sid=680948ff-f239-4b8b-8ac1-43da0d5470e5?hide_owner=true?hide_share=true?hide_title=true?hideEmbedTopBar=true"
                            frameBorder="0"
                            allowFullScreen
                            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                        />
                    </div>
                </div>
            }
            onNextClick={() => {
                props.jumpToStep(1)
            }}
        />
    );

export default InterviewIntro