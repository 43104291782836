import React, {useContext, useEffect, useRef, useState} from 'react'
import VideoRecorder from 'react-video-recorder'
import InterviewContext from "../../contexts/interview";
import {getInterviewInfo, makePostRequest, submitInterviewVideoBlob, subtractAttempt} from "../../api/submit";
import Button from "../../components/Buttons/Button"
import renderActions from "../../components/Interview/Actions"
import styles from "./Interview.module.css"
import {API, BACKEND} from "../../constants";
import {createRecommendedGA4Event} from "../../api/ga4";
import Loader from "../../components/General/Loader";

const Interview = ({setQuestionExists, ...props}) => {
    const {
        accessCode,
        attemptsLeft,
        setAttemptsLeft,
        question,
        setQuestion,
    } = useContext(InterviewContext)


    const [recordingComplete, setRecordingComplete] = useState(false)
    const [cameraOn, setCameraOn] = useState(false)
    const [videoBlob, setVideoBlob] = useState(null)
    const [timeLimit, setTimeLimit] = useState(120000)
    const [countdownTime, setCountdownTime] = useState(3000)
    const [questionOrder, setQuestionOrder] = useState({"current": 0, "total": 0})
    const [submitButtonTitle, setSubmitButtonTitle] = useState("Next Question...")
    const [isLoading, setIsLoading] = useState(false)

    let videoRecorderRef = useRef(null)

    const recordGA4Event = (name) => {
        createRecommendedGA4Event(name, {
            'label': accessCode,
            'category': 'Candidate Interview Funnel',
            'question': questionOrder.current
        });
    }

    const loadQuestion = () => {
        return getInterviewInfo({interview_access_code: accessCode}).then(response => {
            if (response?.error) {
                setQuestionExists(false)
                props.jumpToStep(2)
            } else {
                setAttemptsLeft(response.attemptsLeft)
                setQuestion(response.question)
                setTimeLimit(response.timeLimit)
                setQuestionOrder({current: response.currentQuestion, total: response.totalQuestions})
            }
        })
    }

    useEffect(() => {
        if (accessCode !== undefined) {
            recordGA4Event('open_interview');
            loadQuestion()
        }
    }, [accessCode])

    useEffect(() => {
        if (cameraOn) {
            //videoRecorderRef.current.handleStartRecording()
        }
    }, [cameraOn])

    const submitResponse = (loadQuestionOnSuccess = true) => {
        return makePostRequest(`${BACKEND}${API}/get_presigned_url_for_upload/`, {interview_access_code: accessCode}).then(response => {
            return submitInterviewVideoBlob(videoBlob, response).then(response => {
                // move the if statement outside the promise if you want to proceed to the next question before this one is uploaded
                makePostRequest(`${BACKEND}${API}/mark_recording_as_complete/`, {interview_access_code: accessCode}).then(response => {
                    if (loadQuestionOnSuccess) {
                        loadQuestion().then(() => {
                            videoRecorderRef.current.handleStopReplaying()
                        })
                    }
                })

            })
        })
    }

    const rerecordResponse = () => {
        recordGA4Event('rerecord_interview_response');
        videoRecorderRef.current.handleStopReplaying()
    }

    const nextQuestionOnClick = () => {
        recordGA4Event('attempt_load_next_interview_question');
        if (!isLoading) {
            setIsLoading(true)
            setSubmitButtonTitle("Loading...")
            submitResponse().then(() => {
                setSubmitButtonTitle("Next Question")
                setRecordingComplete(false)
                setIsLoading(false)
                recordGA4Event('success_load_next_interview_question');
            });
        }
    }


    return (
        <div className={styles.interview_page}>
            <Loader isLoading={isLoading} text={"Uploading Your Response...Please Do Not Reload The Page"} useUnloadCallback={true}/>
            <div className={styles.main_content}>
                <div className={styles.interview_header}>
                    <h2>Question {questionOrder.current} / {questionOrder.total}</h2>
                    <h1>{question}</h1>
                </div>
                <div className={styles.videos_container}>
                    <div id={styles.recruiter_video} className={styles.video_box}>

                    </div>
                    <div className={styles.video_box}>
                        {attemptsLeft || true ? <VideoRecorder
                            isOnInitially={true}
                            onCameraOn={() => {
                                setCameraOn(true)
                            }}
                            onRecordingComplete={(videoBlob) => {
                                setRecordingComplete(true)
                                setVideoBlob(videoBlob)
                                setCountdownTime(3000)
                                makePostRequest(`${BACKEND}${API}/get_attempts_left/`, {interview_access_code: accessCode}).then(response => {
                                    setAttemptsLeft(response.attemptsLeft)
                                })
                            }}
                            onStartRecording={() => {
                                subtractAttempt({interview_access_code: accessCode})
                            }}

                            // the below prop does not seem to be needed
                            onStopReplaying={() => {
                            }}
                            countdownTime={countdownTime}
                            timeLimit={timeLimit}

                            // uncomment the line below to show user replay controls
                            showReplayControls={false}
                            renderDisconnectedView={() => <div>Please, turn on your camera and ensure you've allowed
                                this
                                website to use it.</div>}
                            renderActions={renderActions}
                            ref={videoRecorderRef}
                        /> : null}
                    </div>
                </div>
                <div className={styles.buttons_container}>
                    <span className={styles.attempts}>Remaining attempts: {attemptsLeft}</span>
                    {(recordingComplete && attemptsLeft > 0) ?
                        <Button title={"Record Again"} highlight={false} onClick={() => {
                            rerecordResponse();
                            setRecordingComplete(false);
                        }}/> : null}
                    {(recordingComplete) ? (
                        <Button title={submitButtonTitle} highlight={!isLoading}
                                onClick={nextQuestionOnClick}/>) : null}

                </div>
                <div className={styles.notes_container}>
                    <textarea placeholder="Space to gather your thoughts..."/>
                    <span className={styles.notes_warning}>This is your private space and we will not use this data for application evaluation.</span>
                </div>
            </div>
        </div>)
}

export default Interview
