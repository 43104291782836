import React, {useEffect} from "react";
import {BarLoader} from 'react-spinner-animated';

import 'react-spinner-animated/dist/index.css'
import Modal from "react-modal";


const Loader = ({isLoading = false, text = 'Loading', useUnloadCallback=false}) => {

    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };

        if (isLoading && useUnloadCallback) {
            window.addEventListener("beforeunload", unloadCallback);
            return () => window.removeEventListener("beforeunload", unloadCallback);
        }
        else {
            window.removeEventListener("beforeunload", unloadCallback);
        }
    }, [isLoading])

    return (<div>
        {(isLoading) &&
            <Modal
                isOpen={true}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        zIndex: 100
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'white',
                        borderRadius: '22px',
                        padding: '17px',
                        zIndex: 101,
                        overflow: 'hidden'
                    }
                }}
            >
                <BarLoader text={text} bgColor={"none"} center={false}
                           width={"150px"} height={"150px"}/>
            </Modal>}

    </div>)
}

export default Loader