import React from 'react';
import styles from "../../components/CandidatePage/CandidateHeader.module.css";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";

// Sample data for demonstration


const ApplicationTable = ({interviews}) => {
    const handleContinue = (id) => {
        alert(`Continuing application for ID: ${id}`);
    };

    const prettify = (text) => {
        return text.replace(/_/g, ' ');
    }

    return (
        <div className="table-container">
            <table>
                <thead>
                <tr>
                    <th>Company</th>
                    <th>Position Name</th>
                    <th>Salary Range</th>
                    <th>Application Started</th>
                    <th>Deadline</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {interviews.map((app) => (
                    <tr key={app.id}>
                        <td>{app.company}</td>
                        <td>{app.title}</td>
                        <td>{(app.salary_lower_bound && app.salary_lower_bound)?(`${app.salary_lower_bound} - ${app.salary_upper_bound}`):('-')}</td>
                        <td>{app.timestamp_started || 'Not Available'}</td>
                        <td>{app.application_deadline || '-'}</td>
                        <td>{prettify(app.status)}</td>
                        <td>
                            {app.status !== 'completed' ? (
                                <Link to={`/interview/${app.access_code}`}><Button variant="outlined">Continue</Button></Link>
                            ) : (
                                <Button variant="outlined" disabled>Completed</Button>
                            )}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default ApplicationTable;
