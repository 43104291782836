import React from 'react';
import styles from './CandidateCell.module.css'
import Avatar from 'react-avatar';
import {generateColor, generateTextColor} from '../../utils/avatarColor';
import {Rating} from "react-simple-star-rating";

const CandidateCell = ({name, email, handleRating, currentRating, onAvatarClick, isAvatarClickable=false}) => {
    let bgColor = generateColor(name);
    return (
        <div className={styles.wrapper} onClick={(event) => {event.stopPropagation()}}>
            <Avatar
                className={isAvatarClickable?styles.circled_avatar:null}
                name={name}
                round={true}
                color={bgColor}
                fgColor={generateTextColor(bgColor)}
                size="48"
                onClick={isAvatarClickable?onAvatarClick:null}
            />
            <div className={styles.name_email} onClick={(event) => {
                event.stopPropagation()
            }}>
                <p className={styles.name}>
                    {name}
                </p>
                <p className={styles.email}>{email}</p>
                <Rating onClick={handleRating} allowFraction size={20} fillColor={'#8600CE'}
                        initialValue={currentRating}/>
            </div>
        </div>
    );
};

export default CandidateCell;
