import React, {useEffect} from 'react';
import Button2 from "../Buttons/Button2";
import {copyTextToClipboard} from "../../utils/common";
import styles from './IncompleteAICell.module.css'

const IncompleteAICell = ({text, pClassName, interviewReminderText}) => {
    useEffect(() => {
        console.log(pClassName)
    }, [])
    const redTexts = ['Incomplete', 'Not Started', 'In Progress', '']
    return (
        <div onClick={(event) => {
            event.stopPropagation()
        }} className={styles.wrapper}>
            <p className={
                redTexts.includes(text) ? `${styles.incomplete} ${pClassName}` : `${styles.cell_text} ${pClassName}`
            }>
                {text}
            </p>
            <Button2
                icon={<img src={'/copy-icon.svg'}/>}
                title={'Copy Reminder Message'}
                onClick={() => {
                    copyTextToClipboard(interviewReminderText)
                }}
                className={styles.gray_button}
                textClassName={styles.gray_button_text}
                isSmall
            />
        </div>
    );
};

export default IncompleteAICell;
