import styles from "./InterviewCreationWizardMenu.module.css";
import NavigationItem from "../Wizard/NavigationItem";
import React from "react";

function InterviewCreationWizardMenu({steps, onCurrentClick, jumpToStep, setIsBackPopupOpen}) {
    return <div className={styles.topMenuWrapper}>
                <div className={styles.left_back}>
                    <button onClick={() => {setIsBackPopupOpen(true)}} className={styles.back_btn_wrapper}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="18" viewBox="0 0 27 18" fill="none">
                            <path d="M27 7.5H5.745L11.115 2.115L9 0L0 9L9 18L11.115 15.885L5.745 10.5H27V7.5Z"
                                  fill="#777777"/>
                        </svg>
                    </button>
                </div>
                <div className={styles.left_menu_wrapper}>
                    <div className={styles.left_menu}>
                        <div className={styles.left_menu_top_text}>
                            <h1 className={styles.arci_gray_text}>create new position</h1>
                            <p className={styles.gray_text}>Create a job description, qualifications, and interview
                                questions — with the help of AI or using your own expertise!</p>
                        </div>
                        <div className={styles.left_menu_wizard_items}>
                            {steps.map((item, index) => (
                                <NavigationItem key={index} state={item.status} title={item.name} onClick={() => {
                                    (item.status === 'completed')?onCurrentClick(jumpToStep, index):null
                                }
                            }/>))}
                        </div>
                    </div>
                </div>
            </div>
}

export default InterviewCreationWizardMenu