import Cookies from "js-cookie";
import {BACKEND, LOGIN_URL} from "../constants";

export const handleLogout = async () => {
        const params = {
            headers: { 'X-CSRFToken': Cookies.get('csrftoken')},
            credentials: 'include',
        }
        return await fetch(`${BACKEND}/client/logout`, params).then(response => {
        }).then((response => {
            window.location.href = LOGIN_URL
        }));
    }