import React from 'react';
import styles from './SortIcon.module.css'

const SortIcon = ({ isSorted, isSortedDesc }) => {

    return (
        <div className={styles.wrapper}>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
                {isSorted ? (isSortedDesc ? <path d="M9 4.5L4.5 0.5L0 4.5H9Z" fill="#BBBBBB" /> : <path d="M9 4.5L4.5 0.5L0 4.5H9Z" fill="#131138" />) : <path d="M9 4.5L4.5 0.5L0 4.5H9Z" fill="#BBBBBB" />}
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5" fill="none">
                {isSorted ? (isSortedDesc ? <path d="M0 0.5L4.5 4.5L9 0.5H0Z" fill="#131138" /> : <path d="M0 0.5L4.5 4.5L9 0.5H0Z" fill="#BBBBBB" />) : <path d="M0 0.5L4.5 4.5L9 0.5H0Z" fill="#BBBBBB" />}
            </svg>
        </div>
    );
};

export default SortIcon;
