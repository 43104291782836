import styles from "./Button2.module.css";
import React, {useEffect, useState} from "react";

const Button2 = ({icon, title, onClick, className, textClassName, isSmall=false, isActive=true}) => {

    const [activityStyle, setActivityStyle] = useState(styles.active);
    const [wrapperStyle, setWrapperStyle] = useState(styles.wrapper);
    const [textStyle, setTextStyle] = useState(styles.text);

    useEffect(() => {
        if (isActive){
            setActivityStyle(styles.active)
        } else {
            setActivityStyle(styles.inactive)
        }
    }, [isActive])

    useEffect(() => {
        if (!isSmall){
            setWrapperStyle(styles.wrapper)
            setTextStyle(styles.text)
        } else {
            setWrapperStyle(styles.wrapper + " " + styles.wrapper_small)
            setTextStyle(styles.text + " " + styles.text_small)
        }
    }, [isSmall])

    const handleClick = (e) => {
        if (onClick && isActive) {
            onClick(e);
        }
    }


    return (
        <button className={wrapperStyle + " " + activityStyle + " " + className} onClick={handleClick}>
            {icon && <div className={styles.icon}>{icon}</div>}
            {title && <p className={textStyle + " " + textClassName}>{title}</p>}
        </button>
    )
}

export default Button2;