import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import React, { useState } from 'react';
import styles from "./Response.module.css"
import Tooltip from '@material-ui/core/Tooltip';
import DOMPurify from "dompurify";


function Response({ summaryText, fullText, isOpen }) {
    return (
      <div>
        {isOpen ? (
         <p className={styles.fullText} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(fullText)}}>
          </p>
        ) : (
          <Tooltip title="This summary is generated with AI. Remember, it might hallucinate!">
            <p className={styles['summary-text']} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(summaryText)}}>
            </p>
          </Tooltip>
        )}
      </div>
      );
    }

export default Response;