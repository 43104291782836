import React, {useState, createContext, useEffect} from 'react';
import {useLocation, useParams} from "react-router-dom";


const InterviewContext = createContext();

export const InterviewProvider = ({children}) => {
    const {interviewAccessCode} = useParams()
    const [accessCode, setAccessCode] = useState(undefined);
    const [attemptsLeft, setAttemptsLeft] = useState(null);
    const [question, setQuestion] = useState("question placeholder");
    const [actionsProps, setActionsProps] = useState();

    useEffect(() => {
        setAccessCode(interviewAccessCode)
    }, [interviewAccessCode])


    return (
        <InterviewContext.Provider value={{accessCode, setAccessCode, attemptsLeft, setAttemptsLeft, question, setQuestion, actionsProps, setActionsProps}}>
            {children}
        </InterviewContext.Provider>
    );
};

export default InterviewContext;