import {useMemo} from "react";
import {useTable, useSortBy, useGlobalFilter, useFilters} from "react-table";
import styles from "./CandidatesTable.module.css"
import GeneralCell from "./GeneralCell";
import {GlobalFilter} from "./SearchBar";
import SortIcon from "./SortIcon";


const CandidatesTable = ({_columns, _data, customFilter, defaultSort}) => {
    const columns = useMemo(() => [..._columns], [_columns]);
    const data = useMemo(() => [..._data], [_data]);

    // Add useGlobalFilter to the useTable hook and destructure globalFilter and setGlobalFilter
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter, // for setting the global filter value
        state: {globalFilter}, // current global filter value
    } = useTable(
        {
            columns,
            data,
            globalFilter: customFilter,
            sortTypes: {
                defaultSort: defaultSort,
            },
        },
        useGlobalFilter, // add useGlobalFilter hook
        useFilters, // Add useFilters hook here
        useSortBy,
    );
    return (
        <div className={styles.table_wrapper}>
            {/* apply the table props */}
            <table {...getTableProps()} className={styles.table}>
                <thead>
                <tr>
                    <td colSpan={columns.length - 1}>
                        <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter}/>
                    </td>
                </tr>
                {
                    // Loop over the header rows
                    headerGroups.map((headerGroup) => (
                        // Apply the header row props
                        <tr {...headerGroup.getHeaderGroupProps()} className={styles.header_row}>
                            {
                                // Loop over the headers in each row
                                headerGroup.headers.map((column) => {
                                    // Apply the header cell props
                                    let columnProps = column.getSortByToggleProps()
                                    columnProps.title = undefined
                                    return <th {...column.getHeaderProps(columnProps)}>
                                        <div className={styles.header_cell_wrapper}>
                                            <SortIcon isSorted={column.isSorted} isSortedDesc={column.isSortedDesc}/>
                                            <span>{column.render("Header")}</span>
                                        </div>
                                    </th>
                                })
                            }
                        </tr>
                    ))
                }
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                {
                    // Loop over the table rows
                    rows.map((row, i) => {
                        // Prepare the row for display
                        prepareRow(row);
                        let skipNext = 0;
                        return (
                            <tr className={styles.row} {...row.getRowProps()} onClick={() => {
                                row.original.onRowClick()
                            }} >
                                {
                                    row.cells.map((cell) => {
                                        // Apply the cell props
                                        const cellProps = {...cell.getCellProps(), ...(cell.value?.props?.cellProps || {})}
                                        const obj = <td
                                            className={styles.cell} {...cellProps}>{cell.render("Cell")}</td>
                                        if (cellProps?.colSpan || 1 > 1) {
                                            skipNext += (cellProps?.colSpan || 1) - 1
                                            return obj
                                        }
                                        if (skipNext > 0) {
                                            skipNext--;
                                            return null
                                        } else {
                                            return obj
                                        }

                                    })
                                }
                            </tr>
                        );
                    })
                }
                </tbody>
            </table>
        </div>
    );
};

export default CandidatesTable;