import styles from "./IconButton.module.css";
import React, {useEffect, useState} from "react";

const IconButton = ({activeIcon, inactiveIcon, onClick, className, isActive=true}) => {

    const [activityStyle, setActivityStyle] = useState({});

    useEffect(() => {
        if (isActive){
            setActivityStyle(styles.active)
        } else {
            setActivityStyle(styles.inactive)
        }
    }, [isActive])

    const handleClick = (e) => {
        if (onClick && isActive) {
            onClick(e);
        }
    }


    return (
        <button className={styles.wrapper + " " + activityStyle + " " + className} onClick={handleClick}>
            {isActive && <div className={styles.icon}>{activeIcon}</div>}
            {!isActive && <div className={styles.icon}>{inactiveIcon}</div>}
        </button>
    )
}

export default IconButton;