import React, { useState } from 'react';
import Modal from 'react-modal';
import styles from './Popup.module.css';
import Button from '../Buttons/Button';


// TODO here, the textarea value can be passed initially from outside as a prop, and then changed locally. We might not need it actually and just remove the prop
function Popup({ isOpen, onClose, inputValue, onInputChange, onSubmit }) {
    const handleButtonClick = (e) => {
        e.preventDefault();
        onInputChange(e);
    };

    const propmtHints = ["Summarize leadership experience", "Thoroughly analyze the candidate on their ability to perform under pressure", "Is this candidate a good fit for a young informal team? Why?"]


    return (<Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Custom Prompt"
        style={{
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                zIndex: 100
            },
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                borderRadius: '22px',
                padding: '70px',
                zIndex: 101
            }
        }}
    >
        <form onSubmit={onSubmit}>
            <div className={styles.modalContent}>
                <h2>create a custom prompt</h2>
                <p>Create a prompt and our generative AI will produce a response for all of the candidates. You might need to wait a minute though for all the responses to properly process  😉</p>
                <textarea value={inputValue} placeholder="Type your prompt or select one from below..." onChange={onInputChange} />
                <div className={styles.hints}>
                    <span>Don’t know where to start? Try these prompts:</span>
                    {propmtHints.map((label, key) => (
                        <Button title={label} key={key} highlight={false} onClick={(e) => handleButtonClick(e)} />
                    ))}
                </div>
                <div className={styles.actionButtonsContainer}>
                    <Button highlight={false} onClick={onClose} title={"Close"}></Button>
                    <Button highlight={true} type="submit" title={"Add Prompt"}></Button>
                </div>
            </div>
        </form>
    </Modal>
    )
}
export default Popup;
