import styles from "./Card.module.css";
import React, {useEffect, useRef, useState} from "react";
import Dropdown from "../General/Dropdown";
import Button from "../Buttons/Button";

const Card = ({cardInfo, onChange, dropdownProps, onDelete, dropdownShow}) => {

    const [isEditing, setIsEditing] = useState(cardInfo.prompt === "");
    const [autoFocus, setAutoFocus] = useState(false);
    const textAreaRef = useRef(null);

    const handleDoubleClick = () => {
        setIsEditing(true);
        setAutoFocus(true)
        setTimeout(() => {
            if (textAreaRef.current) {
                textAreaRef.current.focus();

                // Set the selection range to the end of the text
                textAreaRef.current.setSelectionRange(
                    textAreaRef.current.value.length,
                    textAreaRef.current.value.length
                );
            }
        }, 0);
    };

    const handleBlur = () => {
        if (cardInfo.prompt !== "") {
            setIsEditing(false)
        }
    };

    useEffect(() => {
        console.log(cardInfo.prompt)
        if (cardInfo.prompt === "") {
            setIsEditing(true)
        }
    }, [cardInfo.prompt])

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <p className={styles.short_name}>{cardInfo.shortName}</p>
                {isEditing ? (
                    <textarea
                        className={styles.description}
                        ref={textAreaRef}
                        type="text"
                        value={cardInfo.prompt}
                        onChange={onChange}
                        onBlur={handleBlur}
                        autoFocus={autoFocus}
                        placeholder={"enter your qualification here..."}
                    />
                ) : (
                    <div className={styles.description} onClick={handleDoubleClick}>{cardInfo.prompt}</div>
                )}
                {dropdownShow &&
                    <div>
                        <Dropdown {...dropdownProps} />
                    </div>
                }
            </div>
            <div className={styles.dot_menu}>
                <Button title={'Delete'} onClick={onDelete} highlight={false}/>
            </div>
        </div>
    )
}

export default Card;