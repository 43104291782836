import React, {useState, createContext, useContext, useEffect} from 'react';
import useStateCallback from "../modules/stateCallback"
import {AuthContext, AuthRole} from "../auth/AuthContext";
import Button from "@mui/material/Button"
import {LOGIN_URL} from "../constants";

const PositionContext = createContext();

function AccessDenied() {

    return <div>
        <div>You do not have access to this page...</div>
        <Button variant={"contained"} onClick={() => {window.location.href = `${LOGIN_URL}?referrer=${encodeURIComponent(window.location.pathname)}${encodeURIComponent(window.location.hash)}`}} >Log In</Button>
    </div>
}

export const PositionProvider = ({children}) => {
    const [position, setPosition] = useState();
    const [data, setData] = useStateCallback([]);
    const {user} = useContext(AuthContext);

    const handleHeaderButtonClick = (id) => {
        let _data = {...data};
        _data[id.q_type][id.q_num]['hidden'] = !(_data[id.q_type][id.q_num]['hidden'])
        setData(_data);
    };

    return (
        <PositionContext.Provider value={{position, setPosition, data, setData, handleHeaderButtonClick}}>
            {user?.role === AuthRole.CLIENT ? children : <AccessDenied/>}
        </PositionContext.Provider>
    );
};

export default PositionContext;