import React, {useState, useEffect} from 'react';
import {LOGIN_URL} from "../constants";
import Button from "@mui/material/Button";
import {useSearchParams} from "react-router-dom";

function SomethingWentWrong() {
    return <div>
        <div>Something went wrong...</div>
        <Button variant={"contained"} onClick={() => {window.location.href = `${LOGIN_URL}`}} >Back to Log In</Button>
    </div>
}

function Resolve() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [referrer, setReferrer] = useState(undefined);

    useEffect(() => {
        const ref = searchParams.get("referrer") || undefined
        if (ref === undefined) setReferrer(undefined)
        else setReferrer(decodeURIComponent(ref))
    }, [searchParams])

    useEffect(() => {
        if (typeof referrer === "string") {
            window.location.href = referrer
        }
    }, [referrer])

    return <div>
        {typeof referrer === "string" ? <div>Redirecting to <a href={referrer}>{referrer}</a>...</div> : <SomethingWentWrong/>}
    </div>
}

export default Resolve