import React, {useEffect, useState} from "react";
import styles from "./VideoInterview.module.css";
import Button2 from "../Buttons/Button2";
import Button from "../Buttons/Button"
import VideoInterviewCard from "./VideoInterviewCard";
import {makePostRequest} from "../../api/submit";
import {API, BACKEND} from "../../constants";
import IconButton from "../Buttons/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const VideoInterview = ({value, setValue, setIsLoading}) => {
    const timeOptions = {
        60: '1 min',
        90: '1 min 30 sec',
        120: '2 min',
        150: '2 min 30 sec',
    }

    const triesOptions = {
        1: "1 attempt",
        2: "2 attempts",
        3: "3 attempts",
        4: "4 attempts",
        5: "5 attempts",
    }

    const [cards, setCards] = useState(value?.interview || []);
    const [unassessedQualifications, setUnassessedQualifications] = useState([])
    const [refreshQualificationsActive, setRefreshQualificationsActive] = useState(false)

    const moveCard = (index, newIndex) => {
        const updatedCards = [...cards];
        const [movedCard] = updatedCards.splice(index, 1);
        updatedCards.splice(newIndex, 0, movedCard);
        setCards(updatedCards);
    }

    const moveCardUp = (index) => {
        if (index > 0) {
            moveCard(index, index - 1);
        }
    };

    const moveCardDown = (index) => {
        if (index < cards.length - 1) {
            moveCard(index, index + 1);
        }
    };

    const addCard = () => {
        // if an empty card was already added
        if (cards.some((item) => item.prompt.trim() === '')) {
            return;
        }
        const newCard = {
            shortName: "",
            prompt: "",
            allottedTime: 120,
            maxAttempts: 2
        }
        setCards([newCard, ...cards])
    }

    const deleteCard = (index) => {
        const updatedCards = [...cards];
        updatedCards.splice(index, 1);
        setCards(updatedCards);
    }

    const updateDescription = (index, value) => {
        const updatedCards = [...cards];
        updatedCards[index].prompt = value
        setCards(updatedCards);
        setRefreshQualificationsActive(true)
    };

    const updateCardField = (index, value, field) => {
        const updatedCards = [...cards];
        updatedCards[index][field] = value
        setCards(updatedCards);
    };

    const generateQuestion = () => {
        setIsLoading(true)
        makePostRequest(`${BACKEND}${API}/d/interview_creation/generate_interview_question/`, value)
            .then(response => {
                setIsLoading(false)
                const questionText = response.question;
                const shortName = response.shortName;
                const newCard = {
                    shortName: shortName || "",
                    prompt: questionText,
                    allottedTime: 120,
                    maxAttempts: 2
                }
                setCards([newCard, ...cards])
                setRefreshQualificationsActive(true)
            })

    }

    const getQuestionToQualificationMap = () => {
        setIsLoading(true)  // remove
        makePostRequest(`${BACKEND}${API}/d/interview_creation/check_questions_against_qualifications/`, value)
            .then(response => {
                setIsLoading(false)
                for (let key in response.covered) {
                    if (response.covered.hasOwnProperty(key)) {
                        updateCardField(key, response.covered[key], 'qualifications')
                    }
                }
                setUnassessedQualifications(response.uncovered);
                setRefreshQualificationsActive(false);
            })

    }

    useEffect(() => {
        getQuestionToQualificationMap();
    }, [])

    useEffect(() => {
        setValue({
            interview: cards.filter((card) => {
                return card.prompt !== "";
            }),
            unassessedQualifications: unassessedQualifications
        })
    }, [cards])

    return (
        <div className={styles.content_wrapper}>
            <div className={styles.cards_wrapper}>
                <div className={styles.qualification_controls}>
                    <Button2
                        icon={<img src={"/Plus.svg"}/>}
                        title={"Add Question"}
                        onClick={addCard}
                    />
                    <Button2
                        icon={<img src={"/Sparkling2.svg"}/>}
                        title={"Suggest Question"}
                        onClick={generateQuestion}

                    />
                </div>
                {cards.map((card, index) => {
                    return <VideoInterviewCard
                        key={index}
                        cardInfo={card}
                        moveUp={() => moveCardUp(index)}
                        moveDown={() => moveCardDown(index)}
                        onChange={(e) => updateDescription(index, e.target.value)}
                        onDelete={(e) => deleteCard(index)}
                        timeLimitDropdownProps={{
                            options: Object.keys(timeOptions).map((key) => {
                                return {id: key, name: timeOptions[key]}
                            }),
                            id: `${index}`,
                            onChangeFunc: (e, id) => updateCardField(id, e?.value || undefined, 'allottedTime'),
                            value: {value: card.allottedTime || 120, label: timeOptions?.[card.allottedTime || 120]},
                            height: 20,
                            width: 'fit-content'
                        }}
                        triesDropdownProps={{
                            options: Object.keys(triesOptions).map((key) => {
                                return {id: key, name: triesOptions[key]}
                            }),
                            id: `${index}`,
                            onChangeFunc: (e, id) => updateCardField(id, e?.value || undefined, 'maxAttempts'),
                            value: {value: card.maxAttempts || 2, label: triesOptions?.[card.maxAttempts || 2]},
                            height: 20,
                            width: 'fit-content'
                        }}
                        qualificationsArray={card?.qualifications || []}
                    />
                })}
            </div>
            <div className={styles.unassessed_wrapper}>
                <IconButton
                    activeIcon={<img src={"/refreshPurple.svg"}/>}
                    inactiveIcon={<img src={"/refreshGray3.svg"}/>}
                    onClick={getQuestionToQualificationMap}
                    isActive={refreshQualificationsActive}
                />
                <div>
                    {unassessedQualifications.length > 0 && <>
                        <p className={styles.uncovered_text}><b>Qualifications Not Assessed by Current Questions</b></p>
                        <div className={styles.unassessed_qualifications}>
                            {unassessedQualifications.map((q, index) => {
                                return <Tooltip title={q?.prompt || "HELLO"}>
                                    <div className={styles.unassessed_qualification}>
                                        <Button
                                            className={styles.inactive_button}
                                            key={index}
                                            title={q?.shortname || "Qualification"}
                                            onClick={() => {
                                            }}/>
                                    </div>
                                </Tooltip>
                            })}
                        </div>
                    </>}
                </div>

            </div>
        </div>)
}

export default VideoInterview