import React, {useState} from 'react';
import styles from "./Question.module.css"; //change it to local styles!!
import Tooltip from '@material-ui/core/Tooltip';
import DOMPurify from 'dompurify'


function Question({summaryText, fullText, isOpen, isAI}) {
    return (
        <div className={styles.questionWrapper}>
            {isOpen ? (
                <b dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(fullText)}}>
                </b>
            ) : (
                <Tooltip title="This summary is generated with AI. Remember, it might hallucinate!">
                    <div className={styles.aiIcon1Parent}>
                        {isAI && <img
                            className={styles.aiIcon1}
                            alt=""
                            src="/ai-icon-1@2x.png"
                        />}
                        <b className={isAI?styles.conflictResolution:styles.nonAI}
                           dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(summaryText)}}>
                        </b>
                    </div>
                </Tooltip>
            )}
        </div>
    );
}

export default Question