import Chatbot, { createChatBotMessage, createClientMessage, createCustomMessage } from "react-chatbot-kit";
import ActionProvider from '../components/ChatBot/ActionProvider';
import MessageParser from '../components/ChatBot/MessageParser';
import 'react-chatbot-kit/build/main.css'
import Config from '../components/ChatBot/config';
import { getRoleplayInfo, processRoleplayReply } from '../api/submit'
import { useEffect, useState, useContext } from "react";
import RoleplayContext from "../contexts/roleplay";
import styles from './Roleplay.module.css';
import Button from '../components/Buttons/Button'
import ConfirmationModal from "../components/General/ConfirmationModal";
import Confetti from 'react-confetti';


const Roleplay = () => {
    const { accessCode, questionNumber, attemptsLeft, setAttemptsLeft } = useContext(RoleplayContext)
    const [config, setConfig] = useState(null);
    const [question, setQuestion] = useState({});
    const [isModalOpen, setModalOpen] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);

    const validator = (input) => {
        return input.length > 0;
    }

    useEffect(() => {
        if (accessCode === undefined || questionNumber === undefined)
            return

        getRoleplayInfo({
            interview_access_code: accessCode, question_number: parseInt(questionNumber, 10)
        }).then((response) => {
            let initialMessages = response.initial_messages.map((msg) => {
                if (msg.role === response.question.bot_role) {
                    return createChatBotMessage(msg.reply, {})
                } else if (msg.role === response.question.user_role) {
                    return createClientMessage(msg.reply, {})
                }
            })
            const chatBotInfo = {
                initialMessages: initialMessages,
                username: response.username
            }
            setConfig(new Config(chatBotInfo))
            setAttemptsLeft(response.attempts_left)
            setQuestion(response.question)
        })

    }, [accessCode, questionNumber])

    const endEarly = () => {
        setModalOpen(false)
        processRoleplayReply({
            interview_access_code: accessCode,
            question_number: questionNumber,
            reply: `${question.early_end}\n`
        }).then((response) => {
            setAttemptsLeft(response.attempts_left)
        })
    }

    function deactivateRoleplay() {
        // hides chat input
        document.getElementsByClassName("react-chatbot-kit-chat-input")[0].classList.add("no-display");

        //hides send button
        document.getElementsByClassName("react-chatbot-kit-chat-btn-send")[0].classList.add("no-display");

        // hides End Early button
        document.getElementsByClassName("end_early")[0].classList.add("no-display");

        setShowConfetti(true)
        setTimeout(() => {
            setShowConfetti(false)
        }, 5000)
    }
    useEffect(() => {
        if (attemptsLeft < 1) {
            deactivateRoleplay();
        }
    }, [attemptsLeft])

    return (
        <div className={styles.role_play_page}>
            <div className={styles.content}>

                {attemptsLeft < 1 ? (<h1 className={styles.rp_title}>Role Play: Completed <span>&#10004;</span></h1>) : (<h1 className={styles.rp_title}>Role Play</h1>)}
                <div className={styles.content_boxes}>
                    <div className={styles.box_child}>
                        <div className={styles.instruction_wrapper}>
                            {question?.goal ? (<div className={styles.instruction_child}>
                                <h2 className={styles.instructions_title}>
                                    Goal
                                </h2>
                                <p className={styles.instructions_paragraph}>
                                    {question.goal}
                                </p>
                            </div>) : null}
                            <div className={styles.instruction_child}>
                                <h2 className={styles.instructions_title}>
                                    Instructions
                                </h2>
                                <p className={styles.instructions_paragraph}>
                                    {question?.prompt}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={styles.box_child}>
                        <Chatbot actionProvider={ActionProvider} messageParser={MessageParser} config={config}
                            validator={validator} />
                    </div>
                </div>

                <div className={styles.controls_wrapper}>
                    <p className={styles.msgs_count}>
                        Messages left: {attemptsLeft}
                    </p>
                    <Button title={"End early"} highlight={false} className={'end_early'} onClick={() => setModalOpen(true)} />
                    <ConfirmationModal
                        isOpen={isModalOpen}
                        onRequestClose={() => setModalOpen(false)}
                        onConfirm={endEarly}
                        confirmationQuestion={"are you sure you want to end the role play early?"}
                        confirmationText={"Submit early"}
                        closeText={"Continue role play"}
                        clarification={`You still have ${attemptsLeft} messages that you can send.`}
                        />
                </div>
            </div>
            {showConfetti && <Confetti />}
        </div>

    );
}

export default Roleplay;
