import React, {useEffect} from 'react';
import styles from './TextCell.module.css'

const TextCell = ({ text, pClassName}) => {
    useEffect(() => {
        console.log(pClassName)
    }, [])
    const redTexts = ['Incomplete', 'Not Started', 'In Progress', '']
    return (
        <div>
            <p className={
                redTexts.includes(text)?`${styles.incomplete} ${pClassName}`:`${styles.cell_text} ${pClassName}`
            }>
                {text}
            </p>
        </div>
    );
};

export default TextCell;
