import styles from "./TextField.module.css";
import Button from "../Buttons/Button";
import React, {useEffect, useState} from "react";

const TextField = ({headerText, descriptionText, placeholderText, hintText, promptHints, passText, textareaClassName, initialInputValue, errorText}) => {

    const [inputValue, setInputValue] = useState(initialInputValue);
    let initialInputValuePassed = false;

    const updateText = (e) => {
        e.preventDefault();
        const newValue = e.target.tagName.toLowerCase() === 'button' ? e.target.innerText : e.target.value;
        setInputValue(newValue);
    };

    useEffect(() => {
        passText(inputValue);
    }, [inputValue])

    useEffect(() => {
        if (initialInputValuePassed) {
            setInputValue(initialInputValue);
            initialInputValuePassed = false
        }
    }, [initialInputValue])

    return (
        <div className={styles.wrapper}>
            { headerText && <h2 className={styles.headerText}>{headerText}</h2>}
            { descriptionText && <p className={styles.descriptionText}>{descriptionText}</p>}
            <textarea value={inputValue?inputValue.replaceAll("<br>", "\n"):inputValue} placeholder={placeholderText}
                      onChange={(e) => updateText(e)}
                      className={`${styles.textareaContent} ${textareaClassName} `}
            />
            { errorText && <p className={styles.error_text}>{errorText}</p>}
            {promptHints && <div className={styles.hints}>
                <span className={styles.hintText}>{hintText}</span>
                {promptHints.map((label, key) => (
                    <Button title={label} key={key} highlight={false} onClick={(e) => updateText(e)}/>
                ))}
            </div>}
        </div>)
}
export default TextField;