import React, {useContext, useEffect, useState} from 'react';
import styles from './Dropdown.module.css';
import Select, {components} from 'react-select';


function Dropdown({options, iconUrl, color, onChangeFunc, id, hint, value, height, width}) {
    // Convert options to the format expected by react-select
    const selectOptions = options.map(option => ({value: option.id, label: option.name}));

    // Custom Control component to include the icon
    const Control = ({children, ...props}) => (
        <div className={styles.dropdownControl}>
            {iconUrl && <img className={styles.vectorIcon} alt="" src={iconUrl}/>}
            <components.Control {...props}>
                {children}
            </components.Control>
        </div>
    );


    return (
        <Select
            placeholder={hint}
            className={styles.jobSelection}
            options={selectOptions}
            isClearable
            isSearchable
            components={{Control}}
            onChange={(e) => onChangeFunc ? onChangeFunc(e, id) : ""}
            value={value || undefined}
            styles={{
                control: (provided) => ({
                    ...provided,
                    height: height || 52,
                    width: width || 233,
                    maxWidth: 233,
                    borderColor: 'var(--color-gray-4)',
                    boxShadow: 'none',
                    '&:hover': {
                        borderColor: 'var(--color-gray-4)',
                    },
                }),
                option: (provided) => ({
                    ...provided,
                    padding: '20px',
                }),
            }}
        />
    );
}

export default Dropdown;
