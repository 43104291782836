import React, {useState, createContext, useEffect} from 'react';
import {useLocation, useParams} from "react-router-dom";


const RoleplayContext = createContext();

export const RoleplayProvider = ({children}) => {
    const {interviewAccessCode, currentQuestionNumber} = useParams()
    const [accessCode, setAccessCode] = useState('');
    const [questionNumber, setQuestionNumber] = useState();
    const [attemptsLeft, setAttemptsLeft] = useState();

    useEffect(() => {
        setAccessCode(interviewAccessCode)
        setQuestionNumber(parseInt(currentQuestionNumber, 10))
    }, [interviewAccessCode, currentQuestionNumber])


    return (
        <RoleplayContext.Provider value={{accessCode, setAccessCode, questionNumber, setQuestionNumber, attemptsLeft, setAttemptsLeft}}>
            {children}
        </RoleplayContext.Provider>
    );
};

export default RoleplayContext;