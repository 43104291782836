import TextField from "../TextField/TextField";
import styles from "./PositionDescription.module.css";
import Dropdown from "../General/DropdownMaterial";
import React, {useContext, useEffect} from "react";
import InterviewCreationContext from "../../contexts/interviewCreation";
import DOMPurify from "dompurify";

const PositionDescription = ({value, setValue}) => {
    const update = (key, value) => {
        setValue((prev) => {
            return {...prev, [key]: value}
        })
    }

    const {errors} = useContext(InterviewCreationContext);

    useEffect(() => {
        console.log("errors in posdesc", errors)
    }, [errors])


    return (<>
        <TextField
            placeholderText={"Paste in your job description or type notes..."}
            passText={(text) => update('jobDescription', text)}
            textareaClassName={styles.bigTextarea}
            initialInputValue={DOMPurify.sanitize(value?.jobDescription) || ""}
            errorText={errors?.jobDescription}
        />
        <TextField
            placeholderText={"Position Name..."}
            passText={(text) => update('positionName', text)}
            textareaClassName={styles.smallTextarea}
            initialInputValue={value?.positionName}
            errorText={errors?.positionName}
        />
        <Dropdown
            options={
                [{id: "English", name: "English"}, {id: "Estonian", name: "Estonian"}]
            }
            id={'languageDropdown'}
            onChangeFunc={(value, id) => {
                update('language', value)
            }}
            value={DOMPurify.sanitize(value?.language) || "English"}
            hint={<div>Select Language...</div>}
        ></Dropdown>
    </>)
}

export default PositionDescription