import styles from "./InterviewCreation.module.css";
import React, {useContext, useEffect, useState} from "react";
import StepZilla from "react-stepzilla";
import BaseLayout from "../components/InterviewCreation/Wizard";
import PositionDescription from "../components/InterviewCreation/PositionDescription";
import Qualifications from "../components/InterviewCreation/Qualifications";
import VideoInterview from "../components/InterviewCreation/VideoInterview";
import {makePostRequest} from "../api/submit";
import {API, BACKEND, CLIENT} from "../constants";
import useStateCallback from "../modules/stateCallback";
import {BarLoader} from 'react-spinner-animated';

import 'react-spinner-animated/dist/index.css'
import InterviewCreationContext, {InterviewCreationProvider} from "../contexts/interviewCreation";
import Modal from "react-modal";
import Popup from "../components/Popup/Base";
import Confetti from "react-confetti";


const MainContent = ({step, contentComponent, contentProps}) => (<>
    <div className={styles.header}>
        <h1 className={styles.arci_black_text}>{step.name.toLowerCase()}</h1>
        <p className={styles.gray_text}>{step.description}</p>
    </div>
    {React.createElement(contentComponent, {...contentProps})}
</>)

let steps = [
    {
        status: 'inProgress',
        name: 'Position Description',
        description: "We use the job description to generate qualifications, questions and candidate assessments. We also show it to candidates. Feel free to use the same one as you would for the job posting on LinkedIn or Indeed.",
        validate: (answers) => {
            const errors = {};
            const keys = ['jobDescription', 'positionName', 'language']
            keys.forEach((key) => {
                if (!answers[key]) {
                    errors[key] = 'This field is required';
                }
            })
            return errors;
        },
        component: PositionDescription,
        onClickLink: 'retrieve_position',
        onLeaveLink: 'submit_or_update_position',
        onNextClickLink: 'submit_or_update_position_and_retrieve_qualifications'
    },
    {
        status: 'notStarted',
        name: 'Qualifications',
        description: "We use the qualifications to assess candidates, generate interview questions and provide candidates with relevant feedback. It is best to coordinate job qualifications with the Hiring Manager.",
        validate: (answers) => {
            const errors = {}
            if ((answers?.qualifications || []).length === 0) {
                errors['cards'] = "You need to add at least one qualification"
            }
            return errors
        },
        component: Qualifications,
        onPrevClickLink: 'retrieve_position',
        onClickLink: 'retrieve_qualifications',
        onLeaveLink: 'submit_or_update_qualifications',
        onNextClickLink: 'submit_or_update_qualifications_and_retrieve_questions'
    },
    {
        status: 'notStarted',
        name: 'Video Interview',
        description: "Create a video interview that candidates will take asynchronously. A good practice is to limit the number of questions to around 3.",
        validate: (answers) => {
            const errors = {}
            if ((answers?.interview || []).length === 0) {
                errors['cards'] = "You need to add at least one interview question!"
            }
            return errors
        },
        component: VideoInterview,
        onPrevClickLink: 'retrieve_qualifications',
        onClickLink: 'retrieve_interview_questions',
        onLeaveLink: 'submit_or_update_interview_questions',
        onNextClickLink: 'submit_or_update_interview_questions'
    },
    // {
    //     status: 'notStarted',
    //     name: 'Role Play',
    //     description: "Role play is a way to directly assess candidate’s skills and knowledge. We will give the task instructions to the candidate, who would need to chat with our AI-powered bot to complete the challenge. The bot can play any role you assign it: from an angry customer to a difficult colleague. Let your imagination flow, provide the context for the role and we will do the rest!",
    //     validate: (answers) => {
    //         return {}
    //     },
    //     component: Roleplay,
    //     onPrevClickLink: 'retrieve_interview_questions',
    //     onNextClickLink: 'submit_or_update_roleplay'
    // },
]

const InterviewCreation = () => {

    const [renderSteps, setRenderSteps] = useState([]);
    const [formData, setFormData] = useStateCallback({});
    const {errors, setErrors} = useContext(InterviewCreationContext);
    const [accessCode, setAccessCode] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isFinishPopupOpen, setIsFinishPopupOpen] = useState(false);
    const [isBackPopupOpen, setIsBackPopupOpen] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);

    useEffect(() => {
        console.log('access code', accessCode)
    }, [accessCode])

    useEffect(() => {
        console.log('errors', errors)
    }, [errors])

    useEffect(() => {
        console.log('formdata', formData)
        if (formData?.positionAccessCode !== undefined) {
            setAccessCode(formData.positionAccessCode)
        } else if (accessCode !== undefined) {
            setFormData((prev) => {
                return {...prev, positionAccessCode: accessCode}
            })
        }
    }, [formData])

    useEffect(() => {
        setRenderSteps(steps.map((item, index) => (
            {
                name: item.name,
                component: <BaseLayout steps={steps} key={index}
                                       setIsBackPopupOpen={setIsBackPopupOpen}
                                       mainContent={<MainContent step={item}
                                                                 contentComponent={item.component}
                                                                 contentProps={{
                                                                     setValue: setFormData,
                                                                     value: formData,
                                                                     setIsLoading: setIsLoading,
                                                                     errors: errors
                                                                 }}
                                       />}
                                       onPrevClick={(index > 0) ? ((jumpToStep) => {
                                           setIsLoading(true)
                                           makePostRequest(`${BACKEND}${API}/d/interview_creation/${steps[index].onLeaveLink}/`, formData)
                                               .then(response => {
                                                   makePostRequest(`${BACKEND}${API}/d/interview_creation/${steps[index].onPrevClickLink}/`, formData)
                                                       .then(response => {
                                                           steps[index].status = 'completed';
                                                           steps[index - 1].status = 'inProgress';
                                                           setFormData(response, () => {
                                                               jumpToStep(index - 1)
                                                           })
                                                           setIsLoading(false)
                                                       })
                                               })
                                       }) : null}
                                       onCurrentClick={(jumpToStep, newIndex) => {
                                           setIsLoading(true)
                                           makePostRequest(`${BACKEND}${API}/d/interview_creation/${steps[index].onLeaveLink}/`, formData)
                                               .then(response => {
                                                   makePostRequest(`${BACKEND}${API}/d/interview_creation/${steps[newIndex].onClickLink}/`, formData)
                                                       .then(response => {
                                                           steps[index].status = 'completed';
                                                           steps[newIndex].status = 'inProgress';
                                                           setFormData(response, () => {
                                                               jumpToStep(newIndex)
                                                           })
                                                           setIsLoading(false)
                                                       })
                                               })
                                       }}
                                       onNextClick={(jumpToStep) => {
                                           setErrors(steps[index].validate(formData), (_errors) => {
                                               if (Object.values(_errors).length) {

                                               } else {
                                                   if (index < steps.length - 1) {
                                                       setIsLoading(true)
                                                       makePostRequest(`${BACKEND}${API}/d/interview_creation/${steps[index].onNextClickLink}/`, formData)
                                                           .then(response => {
                                                               setIsLoading(false)
                                                               steps[index].status = 'completed';
                                                               steps[index + 1].status = 'inProgress';
                                                               setFormData(response, () => {
                                                                   jumpToStep(index + 1)
                                                               })
                                                           })
                                                       // add a catch statement to intercept 409 conflict
                                                   } else {
                                                       setIsFinishPopupOpen(true)
                                                   }
                                               }
                                           });
                                       }}
                />,
            }
        )))
    }, [steps, formData])

    return (<div className={styles.mainWrapper}>
        <StepZilla
            steps={renderSteps}
            startAtStep={0}
            showNavigation={false}
            dontValidate={true}
            stepsNavigation={false}
            showSteps={false}
            preventEnterSubmission={true}
        />
        <Popup
            isOpen={isBackPopupOpen}
            onClose={() => setIsBackPopupOpen(false)}
            onSubmit={() => {
                setIsBackPopupOpen(false)
                window.location.href = `${CLIENT}/`
            }}
            headerText={"Are you sure you want to go back?"}
            paragraphText={"Your progress will be lost."}
            actionButtonText={"I am sure"}
        />
        <Popup
            isOpen={isFinishPopupOpen}
            onClose={() => setIsFinishPopupOpen(false)}
            onSubmit={() => {
                setIsFinishPopupOpen(false)
                setIsLoading(true)
                setShowConfetti(true);
                const timer = setTimeout(() => setShowConfetti(false), 3000);
                makePostRequest(`${BACKEND}${API}/d/interview_creation/${steps[steps.length - 1].onNextClickLink}/`, formData)
                    .then(response => {
                        setIsLoading(false)
                        window.location.href = `${CLIENT}/`
                    })
            }}
            headerText={"Are you sure you want to finish?"}
            paragraphText={"Your interview is almost ready!"}
            actionButtonText={"I am sure"}
        />
        {showConfetti && <Confetti/>}
        {(isLoading) &&
            <Modal
                isOpen={true}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        zIndex: 100
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'white',
                        borderRadius: '22px',
                        padding: '17px',
                        zIndex: 101,
                        overflow: 'hidden'
                    }
                }}
            >
                <BarLoader text={"AI is generating role qualifications..."} bgColor={"none"} center={false}
                           width={"150px"} height={"150px"}/>
            </Modal>}

    </div>)
}

export default InterviewCreation