import React, {useState} from 'react';
import Modal from 'react-modal';
import styles from './Base.module.css';
import Button from '../Buttons/Button';


function Popup({isOpen, onClose, onSubmit, content, headerText, paragraphText, actionButtonText = "Submit"}) {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            if (typeof onSubmit === 'function') {
                await onSubmit(); // Assuming onSubmit is an asynchronous function
            }
        } catch (error) {

        } finally {
            setIsSubmitting(false);
        }
    };

    return (<Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel=""
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    zIndex: 100
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    borderRadius: '22px',
                    padding: '70px',
                    zIndex: 101
                }
            }}
        >
            <form onSubmit={handleSubmit}>
                <div className={styles.modalContent}>
                    {headerText !== undefined && <h2>{headerText}</h2>}
                    {paragraphText !== undefined && <p> {paragraphText}</p>}
                    {content !== undefined && content}
                    <div className={styles.actionButtonsContainer}>
                        {onClose && <Button highlight={false} onClick={onClose} title={"Close"}/>}
                        {onSubmit && <Button highlight={true} type="submit" title={actionButtonText} disabled={isSubmitting}/>}
                    </div>
                </div>
            </form>
        </Modal>
    )
}

export default Popup;
