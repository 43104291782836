import React, {useState} from 'react';
import Modal from 'react-modal';
import styles from './StoriesPopup.module.css';
import Stories from "react-insta-stories";


function StoriesPopup({isOpen, onClose, stories, headerText}) {

    return (<Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel=""
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    zIndex: 100
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    margin: 0,
                    transform: 'translate(-50%, -50%)',
                    background: 'none',
                    borderRadius: 0,
                    padding: 0,
                    zIndex: 101,
                    border: 'none'
                }
            }}
        >
            <div className={styles.modalContent}>
                {headerText !== undefined && <h2>{headerText}</h2>}
                <Stories
                    stories={stories}
                    defaultInterval={15000}
                    width={600}
                    height={800}
                    onAllStoriesEnd={onClose}
                    keyboardNavigation={true}
                />
            </div>
        </Modal>
    )
}

export default StoriesPopup;
