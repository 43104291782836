import { useState } from "react";
import { useAsyncDebounce } from "react-table";
import styles from "./SearchBar.module.css"

export const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <div className={styles.wrapper}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path d="M9.0628 0.519821C12.8882 0.519821 16 3.63165 16 7.45702C16 11.2824 12.8882 14.3942 9.0628 14.3942C7.89339 14.3942 6.7438 14.1167 5.77259 13.5816C5.6948 13.6752 5.60854 13.7614 5.51492 13.8392L3.53287 15.8213C3.34985 16.0272 3.12667 16.1936 2.87704 16.3102C2.6274 16.4267 2.35657 16.491 2.08118 16.4991C1.80579 16.5072 1.53165 16.459 1.27559 16.3573C1.01953 16.2556 0.786954 16.1027 0.59214 15.9079C0.397326 15.713 0.244385 15.4805 0.142709 15.2244C0.0410328 14.9683 -0.00723267 14.6942 0.000874519 14.4188C0.0089817 14.1434 0.0732899 13.8726 0.189852 13.623C0.306415 13.3733 0.472771 13.1502 0.678707 12.9671L2.66076 10.9851C2.75731 10.8885 2.86359 10.8021 2.97789 10.7274C2.44274 9.7562 2.10579 8.62643 2.10579 7.4372C2.10579 3.61183 5.21762 0.5 9.04298 0.5L9.0628 0.519821ZM9.0628 2.50188C6.30775 2.50188 4.10766 4.70196 4.10766 7.45702C4.10766 8.76517 4.58336 9.97423 5.41582 10.8662C5.43564 10.886 5.45546 10.9058 5.47528 10.9256C5.5689 11.0034 5.65516 11.0897 5.73295 11.1833C6.60505 11.9761 7.79429 12.432 9.08262 12.432C11.8377 12.432 14.0378 10.2319 14.0378 7.47684C14.0378 4.72178 11.8377 2.5217 9.08262 2.5217L9.0628 2.50188Z" fill="#777777" />
            </svg>
            <input placeholder={"Search..."} value={value || ""}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}>
            </input>
        </div>
    );
};