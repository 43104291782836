import React, {useState, useEffect, useContext} from 'react';
import styles from "./Credentials.module.css";
import Avatar from 'react-avatar';
import {generateColor, generateTextColor} from '../../utils/avatarColor';
import {handleLogout} from "../../utils/auth";
import {AuthContext} from "../../auth/AuthContext";
import Button from "@mui/material/Button";


function Credentials() {
    const [show, setShow] = useState(false);
    const [bgColor, setBgColor] = useState("#FFCDFF");
    const {user} = useContext(AuthContext);

    useEffect(() => {
        function handleClickOutside(event) {
            if (event.target.className === styles.modal) {
                setShow(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }

    }, []);

    useEffect(() => {
        if (user !== undefined && user?.username !== undefined) {
            setBgColor(generateColor(user.username))
        }
    }, [user])

    return (
        <div className={styles.credentialsWrapper}>
            <div className={styles.menuBottomLogin}>
                <div className={styles.avatarParent}>
                    <Avatar
                        name={user?.username}
                        round={true}
                        color={bgColor}
                        fgColor={generateTextColor(bgColor)}
                        size="48"
                    />
                    <div className={styles.nameEmail}>
                        <div className={styles.conflictResolution}>
                            {user?.username}
                        </div>
                        <div className={styles.email}>
                            {user?.email}
                        </div>
                    </div>
                </div>
                <button className={styles.dotMenu} id="settings_button" onClick={() => setShow(!show)}>
                    <div className={styles.dotMenuChild}/>
                    <div className={styles.dotMenuChild}/>
                    <div className={styles.dotMenuChild}/>
                </button>
                {show && (
                    <div className={styles.modal}>
                        <div className={styles.modalContent}>
                            <button onClick={handleLogout}>Log out</button>
                        </div>
                    </div>
                )}
            </div>
            <div>
                <Button variant={"outlined"} onClick={() => window.location.href = '/candidate/#/d1'}>Candidate
                    View</Button>
            </div>
        </div>
    )
}

export default Credentials