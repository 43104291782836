import Authentication from "../components/General/Authentication"
import React, {useState} from "react";
import {makePostRequest} from "../api/submit";
import {API, BACKEND, CLIENT, LOGIN_URL} from "../constants";
import {useSearchParams} from "react-router-dom";


const Register = () => {
    const [formError, setFormError] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    const formHandleSubmit = (answers) => {
        makePostRequest(`${BACKEND}${API}/register/`, {
            username: answers.email,
            email: answers.email,
            password: answers.password,
            first_name: answers.firstName,
            last_name: answers.lastName,
        }).then(response => {
            const status = response._metadata.status;
            if (status === 200){
                window.location.href = `${LOGIN_URL}?${searchParams.toString()}`
            }
            else if (status === 409) {
                setFormError('Email already exists')
            }
            else {
                setFormError('Something went wrong. Please try again later')
            }
        })
    }

    const formValidate = (answers, setErrors) => {
        const newErrors = {};
        questions.forEach(({id, type, required}) => {
            if (!answers[id] && required) {
                newErrors[id] = 'This field is required';
            } else if (type === 'email' && !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,12}$/.test(answers[id])) {
                newErrors[id] = 'Invalid email format';
            }
        });
        setErrors(newErrors);
        return !Object.values(newErrors).length;
    }

    const questions = [
        {
            id: "email",
            label: "Email",
            type: "email",
            required: true
        },
        {
            id: "firstName",
            label: "First Name",
            type: "text",
            required: true
        },
        {
            id: "lastName",
            label: "Last Name",
            type: "text",
            required: true
        },
        {
            id: "password",
            label: "Password",
            type: "password",
            required: true
        },

    ]
    return (
        <Authentication formQuestions={questions} formValidate={formValidate} formHandleSubmit={formHandleSubmit}
                        isLoginPage={false} formError={formError}/>)
};

export default Register;