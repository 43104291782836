import styles from "./Candidates.module.css";
import CandidatesTable from "../../components/Candidates/CandidatesTable";
import LeftSidebar from "../../components/Sidebar/Sidebar";
import GeneralCell from "../../components/Candidates/GeneralCell";
import CandidateCell from "../../components/Candidates/CandidateCell";
import Status from "../../components/Candidates/Status";
import TextCell from "../../components/Candidates/TextCell";
import {formatEuropeanDate} from "../../utils/dateTime";
import {makePostRequest} from "../../api/submit";
import React, {useContext, useEffect, useState} from "react";
import PositionContext from "../../contexts/position";
import {API, BACKEND} from "../../constants";
import Tooltip from "@material-ui/core/Tooltip";
import './CandidatesComments.css'
import {getAIMatchSortValue, replaceUnderscoresAndCapitalize} from "../../utils/common";
import CopyCandidatesPopup from "./CopyCandidatesPopup";
import Button from "../../components/Buttons/Button";
import CandidateInfoPopup from "./CandidateInfoPopup";
import IncompleteAICell from "../../components/Candidates/IncompleteAICell";
import StoriesPopup from "../../components/Popup/StoriesPopup";
import {AuthContext} from "../../auth/AuthContext";


const customFilter = (rows, columns, filterValue) => {
    if (!filterValue) {
        return rows;
    }
    return rows.filter((row) => {
        // Loop through all columns and check if any of them include the filterValue
        return columns.some((column) => {
            const cellValue = row.values[column]?.props?.filterValue;
            return cellValue && cellValue.toString().toLowerCase().includes(filterValue.toLowerCase());
        });
    });
};

const defaultSort = (a, b, columnName, desc) => {
    let aVal = a.original[columnName]?.props?.sortValue
    let bVal = b.original[columnName]?.props?.sortValue
    if (typeof aVal === 'string' && typeof bVal === 'string') {
        aVal = aVal.toLowerCase()
        bVal = bVal.toLowerCase()
    }
    if (aVal === bVal) {
        return 0
    }
    if (aVal === undefined) {
        return -1 // the other one is always bigger
    }
    if (bVal === undefined) {
        return 1 // the other one is always bigger
    }
    return aVal > bVal ? 1 : -1;
}


let COLUMNS = [
    {
        Header: "Candidate",
        accessor: "candidate",
        sortable: true,
        sortType: defaultSort,
    },
    {
        Header: "Status",
        accessor: "status",
        sortable: true,
        sortType: defaultSort,

    },
    {
        Header: "Application Date",
        accessor: "applicationDate",
        sortable: true,
        sortType: defaultSort,

    },
    {
        Header: "AI Match",
        accessor: "aiMatch",
        sortable: true,
        sortType: defaultSort,
    }
];

const Candidates = () => {
    const {user} = useContext(AuthContext);
    const {position} = useContext(PositionContext);

    const [columns, setColumns] = useState([])
    const [data, setData] = useState([])
    const [candidateInfo, setCandidateInfo] = useState(undefined)
    const [completedInterviews, setCompletedInterviews] = useState(0)
    const [isSuperUser, setIsSuperUser] = useState(false)
    const [isCopyModalOpen, setIsCopyModalOpen] = useState(false)
    const [stories, setStories] = useState([])
    const [isStoriesModalOpen, setIsStoriesModalOpen] = useState(false)

    useEffect(() => {
        const hideElements = (className) => {
            const elements = document.getElementsByClassName(className);
            for (const element of elements) {
                element.style.display = 'none';
            }
        };
        hideElements('replyBtn');
    })

    useEffect(() => {
        makePostRequest(`${BACKEND}${API}/is_superuser/`, {})
            .then((response) =>
                setIsSuperUser(response.is_superuser)
            )
    }, [])

    useEffect(() => {
        if (position === undefined) {
            setColumns([])
            setData([])
            return
        }
        loadContent();
        console.log(`USER`, user.id, user.username)
    }, [position])

    const loadContent = () => {
        makePostRequest(`${BACKEND}${API}/d/candidates/fetch_metrics/`, {position_id: position})
            .then((response) => {
                setColumns([...COLUMNS, ...response.metrics.question_based
                    .slice()
                    .filter((a) => {
                        return a.question_type === "qualification"
                    })
                    .map((question, index) => {
                        return {
                            Header: (
                                <Tooltip title={question.question_prompt || ""}>
                                    <div>{question.question_shortname}</div>
                                </Tooltip>),
                            accessor: question.question_type + question.question_number,
                            sortable: true,
                            sortType: defaultSort,
                        }
                    })])
            })

        makePostRequest(`${BACKEND}${API}/d/candidates/fetch_general_info_and_scores/`, {position_id: position})
            .then((response) => {
                console.log(`fetch_general_info_and_scores output`, response)
                let _completedInterviews = 0
                setData(response.candidates.map((c, index) => {
                    _completedInterviews += (c?.completion_status === 'completed')
                    const aiMatchText = replaceUnderscoresAndCapitalize(c?.completion_status || 'Not Started')
                    let interviewReminderText = ''
                    if (aiMatchText === 'Not Started') {
                        interviewReminderText = `Hi ${c.name}!
                        
This is a reminder that you've applied for the ${response.position.name || ""} position at ${response.position.company.name || ""}. As the next step, you need to complete a video interview using the following link: ${c.interview_link}

You may take it from your phone or your computer.`
                    } else if (aiMatchText === 'In Progress') {
                        interviewReminderText = `Hi ${c.name}!
                        
This is a reminder that you've started the video interview process for the ${response.position.name || ""} position at ${response.position.company.name || ""}. Don't forget to finish it at: ${c.interview_link}`
                    }
                    let datum = {
                        candidate: <GeneralCell sortValue={c.name}
                                                filterValue={c.name + c.email}
                                                child={<CandidateCell
                                                    name={c.name}
                                                    email={c.email}
                                                    handleRating={(value) => {
                                                        makePostRequest(`${BACKEND}${API}/d/candidates/update_rating/`, {
                                                            position_id: position,
                                                            interviewee_id: c.id,
                                                            rating: value
                                                        })
                                                    }}
                                                    currentRating={c.rating || 0}
                                                    onAvatarClick={() => {
                                                        setIsStoriesModalOpen(true)
                                                        setStories(c.links.map((obj) => {
                                                                return {
                                                                    url: obj.link,
                                                                    type: 'video',
                                                                    header: {
                                                                        heading: obj.prompt
                                                                    }
                                                                }
                                                            }
                                                        ))
                                                    }}
                                                    isAvatarClickable={c.links.length > 0}
                                                />}
                        />,
                        status: <GeneralCell sortValue={c.status} filterValue={c.status}
                                             child={<Status initialStatus={c.status} onChangeStatus={(e) => {
                                                 makePostRequest(`${BACKEND}${API}/d/candidates/update_label/`, {
                                                     position_id: position,
                                                     interviewee_id: c.id,
                                                     label: e
                                                 })
                                             }}/>}/>,
                        applicationDate: <GeneralCell sortValue={c.application_date}
                                                      filterValue={formatEuropeanDate(c.application_date)}
                                                      child={<TextCell
                                                          text={formatEuropeanDate(c.application_date)}/>}/>,
                        aiMatch: <GeneralCell sortValue={getAIMatchSortValue(aiMatchText)} filterValue={aiMatchText}
                                              child={
                                                  <IncompleteAICell
                                                      text={aiMatchText}
                                                      interviewReminderText={interviewReminderText || undefined}
                                                  />}
                                              cellProps={{colSpan: 2}}
                        />,
                        name: c.name,
                        statusString: aiMatchText,
                        interviewId: c.interview_id,
                        candidateId: c.id,
                    }
                    let aiMatchValue = []
                    for (const i in c.scores.question_based) {
                        const s = c.scores.question_based[i]
                        if (s.question_type !== 'qualification') {
                            continue
                        }
                        const score = s.score_with_sentiment;
                        const cellText = (score !== undefined && score !== '') ? score.toFixed(2) + "%" : " ";
                        datum[`${s.question_type}${s.question_number}`] =
                            <GeneralCell sortValue={score} filterValue={score}
                                         child={<TextCell text={cellText}/>} tooltipText={s?.justification}/>
                        if (score !== undefined) {
                            aiMatchValue.push(score)
                        }
                    }
                    if (aiMatchValue.length > 0) {
                        const sum = aiMatchValue.reduce((total, num) => total + num, 0);
                        const avg = parseFloat((sum / aiMatchValue.length).toFixed(2));
                        datum['aiMatch'] = <GeneralCell sortValue={avg} filterValue={avg}
                                                        child={<TextCell text={`${avg.toString()}%`}
                                                                         pClassName={styles.aiMatchCellText}/>}/>
                    }
                    datum['onRowClick'] = () => {
                        setCandidateInfo(datum)
                    }
                    return datum
                }))
                setCompletedInterviews(_completedInterviews)
            })
    }

    return (
        <div className={styles.candidates_page}>
            <StoriesPopup
                isOpen={isStoriesModalOpen}
                onClose={() => {
                    setStories([])
                    setIsStoriesModalOpen(false)
                }}
                stories={stories}
            />
            <LeftSidebar/>
            <div className={styles.main_content}>
                <div className={styles.candidates_header}>
                    <div className={styles.candidates_count_section}>
                        <h1>candidates</h1>
                        <span className={styles.candidates_count}>{completedInterviews}/{data.length}</span>
                    </div>
                    {isSuperUser && <div>
                        <Button
                            title={"Open Copy Modal"}
                            onClick={() => {
                                setIsCopyModalOpen(true)
                            }}
                        />
                    </div>}
                    <div className={styles.filter_section}>
                        <div className={styles.new_filter}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21"
                                 fill="none"
                                 className={styles.funnel}>
                                <path
                                    d="M0.312479 2.57553C0.437471 2.73802 7.48701 11.7374 7.48701 11.7374V19.2494C7.48701 19.9369 8.04947 20.4994 8.74942 20.4994H11.2618C11.9492 20.4994 12.5242 19.9369 12.5242 19.2494V11.7249C12.5242 11.7249 19.3862 2.95051 19.7112 2.55054C20.0362 2.15056 19.9987 1.75059 19.9987 1.75059C19.9987 1.06313 19.4362 0.500671 18.7363 0.500671H1.26242C0.499967 0.500671 0 1.10063 0 1.75059C0 2.00057 0.0749951 2.30055 0.312479 2.57553Z"
                                    fill="white"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19"
                                 fill="none"
                                 className={styles.plus}>
                                <path
                                    d="M18 10.7857H10.2857V18.5H7.71429V10.7857H0V8.21429H7.71429V0.5H10.2857V8.21429H18V10.7857Z"
                                    fill="white"/>
                            </svg>
                        </div>
                        <div className={styles.added_filters}>
                            {/*TODO, add a separate component for filters */}
                        </div>
                    </div>
                </div>
                {position &&
                    <CandidatesTable _columns={columns} _data={data} customFilter={customFilter}></CandidatesTable>}
            </div>
            <CandidateInfoPopup user={user} candidateInfo={candidateInfo} setCandidateInfo={setCandidateInfo}/>
            <CopyCandidatesPopup isOpen={isCopyModalOpen} setIsOpen={setIsCopyModalOpen}/>
        </div>
    )
};

export default Candidates;