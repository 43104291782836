import React, {useContext} from 'react';
import {processRoleplayReply} from '../../api/submit'
import RoleplayContext from "../../contexts/roleplay";
import {createCustomMessage} from "react-chatbot-kit";
import styles from './ActionProvider.module.css'
import ConfirmationModal from '../General/ConfirmationModal';


const ActionProvider = ({createChatBotMessage, setState, children}) => {
    const {accessCode, questionNumber, setAttemptsLeft} = useContext(RoleplayContext)

    const processMessage = (message) => {
        document.getElementsByClassName("react-chatbot-kit-chat-input")[0].disabled = true;
        processRoleplayReply({interview_access_code: accessCode, question_number: questionNumber, reply: message}).then(
            response => {
                const messages = response.replies.map(reply => {
                    if (reply.messageType === "bot") {
                        return createChatBotMessage(reply.content, {});
                    }
                    else if (reply.messageType === "system") {
                        return createCustomMessage(reply.content, 'system', {payload: reply.content} )
                    }
                })
                updateChatbotState(messages)
                setAttemptsLeft(response.attempts_left)
                document.getElementsByClassName("react-chatbot-kit-chat-input")[0].disabled = false;
            }
        )
    }

    const updateChatbotState = (messages) => {
        // NOTE: This function is set in the constructor, and is passed in
        // from the top level Chatbot component. The setState function here
        // actually manipulates the top level state of the Chatbot, so it's
        // important that we make sure that we preserve the previous state.
        if (Array.isArray(messages)) {
            setState(prevState => ({
                ...prevState,
                messages: [...prevState.messages, ...messages]
            }))
        } else {
            setState(prevState => ({
                ...prevState,
                messages: [...prevState.messages, messages]
            }))
        }
    }

    return (
        <div className={styles.wrapper}>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    processMessage: processMessage,
                    actions: {},
                });
            })}
        </div>
    );
}
export default ActionProvider;