import styles from "./NavigationItem.module.css";
import React from "react";

const NavigationItem = ({state, title, onClick}) => {
    const a = {
        completed: (<img src={"/WizardCompleted.svg"}/>),
        inProgress: (<img src={"/WizardInProgress.svg"}/>),
        notStarted: (<img src={"/WizardNotStarted.svg"}/>)
    }
    const curState = a?.[state] ? state : 'notStarted'
    const isActive = (curState === 'completed' || curState === 'inProgress')
    return (
        <div onClick={onClick}
             className={`${styles.wrapper} ${isActive?styles.active:null}`}>
            {a[curState]}
            <p className={isActive ? styles.text_active : styles.text_inactive}>{title}</p>
        </div>
    )
}

export default NavigationItem